import React, {useState, useEffect} from "react";
import * as _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { EvaluatorDialog } from "../../../../components";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { SelectArea, SearchInput } from "components";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import {
	Button,
	Card,
	CardActions,
	CardContent,	
	Dialog,	
	Grid,
	IconButton,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,	
	TablePagination,  
} from "@material-ui/core";


const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},	
});	

const useStyles = makeStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	dialog: {
		width: "75%",
	},
	link: {
		color: theme.palette.primary.main
	},
	actions: {
		display: "flex",
		alignItems: "center",
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2)
	},
	button: {
		marginLeft: 10
	},
	addButton: {
		backgroundColor: theme.palette.primary.main
	},
	row: {
		display: "flex",
		alignItems: "center",
	},
	itemMargin: {
		margin: theme.spacing(.5),
		marginBottom: theme.spacing(1),
	},
}));

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

export default function EvaluatorsListModal(props) {  
	const { opened, setOpen, updateList } = props;	
	const classes = useStyles();
	const [totalEvaluators, setTotalEvaluators] = useState(0);
	const [evaluators, setEvaluators] = useState([]);
	const [page, setPage] = useState(0);  
	const [dialogOpen, setDialogOpen] = useState(false);
	const [consultantSelected, setConsultantSelected] = useState();
	const [search, setSearch] = useState("");
	const projectState = useSelector((state) => state.project.selected);
	const [area, setArea] = useState(projectState.area_id);  
	const [disableAddButton, setDisableAddButton] = useState(false);
	
	const handleClose = () => {
		setOpen(false);
	};
  
	const handleClick = (consultant) => {
		setConsultantSelected(consultant);
		setDialogOpen(true);
	};
  
	const handlePageChange = (event, page) => {		
		setPage(page);
	};	
  
	const addEvaluation = async (consultant) => {
		try {
			setDisableAddButton(true);
			await api.post("/evaluations", {        
				project_id: projectState.id,
				consultant_id: consultant.id
			});			
      

			setEvaluators(evaluators.filter(x => x.consultant.id !== consultant.id));    
			updateList();
			toast.success(`Oba! O avaliador ${consultant.user.name} foi atribuído ao projeto.`);    			
			setDisableAddButton(false);
		} catch (err) {
			toast.error("Ops! Tivemos um erro ao atribuir o avaliador");
			setDisableAddButton(false);
		}
	};
  
	async function loadEvaluators(){
		if (opened === false) return;
		const response = await api.get(`/projects/${projectState.id}/evaluators`, {params: {
			page: page + 1,
			search,
			area
		}});
    
		setEvaluators(response.data.evaluators);
		setTotalEvaluators(response.data.total);
	}
  
	useEffect(() => {				
		loadEvaluators();
	},[opened]);	
  
	useEffect(() => {
		loadEvaluators();
	}, [search]);
  
	useEffect(() => {
		loadEvaluators();
	}, [area]);
  
	useEffect(() => {
		loadEvaluators();
	}, [page]);


	return (
		<div className={classes.dialog}>			
			<Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={opened}>
				<DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Lista de avaliadores 
				</DialogTitle>        
				<EvaluatorDialog opened={dialogOpen} setOpen={setDialogOpen} consultant={consultantSelected} />																
				<DialogContent divviders>	
					<Grid container className={classes.row}>
						<Grid item xs={12} sm={12} md={5} className={classes.itemMargin}>
							<SearchInput
								className={classes.searchInput}
								placeholder="Procurar avaliador por nome"
								value={search}
								onChange={(event) => setSearch(event.target.value)}
							/>
						</Grid>		
						<Grid item xs={12} sm={12} md={6} className={classes.itemMargin}>
							<SelectArea projectArea={area} setArea={setArea}></SelectArea>
						</Grid>
					</Grid>
					{evaluators && evaluators.length > 0? 
          <>        
					<div className={classes.content}>
						<Card
							className={classes.root}
						>
							<CardContent className={classes.content}>				
								<PerfectScrollbar>
									<div className={classes.inner}>
										<Table>
											<TableHead>
												<TableRow>									
													<TableCell>Avaliador</TableCell>									
													<TableCell>Instituição</TableCell>								
													<TableCell>Quantidade de projetos</TableCell>	
													<TableCell></TableCell>							
												</TableRow>
											</TableHead>
											<TableBody>
												{evaluators && evaluators.map(evaluator => (
													<TableRow
														className={classes.tableRow}
														hover
														key={evaluator.id}>																		
														<TableCell>															
															{evaluator.consultant.user.name}
														</TableCell>					
														<TableCell>
															{evaluator.consultant.institution.description}
														</TableCell>
														<TableCell>
															{evaluator.consultant.Evaluations.length}
														</TableCell>
														<TableCell>															
															<Button 
																disabled={disableAddButton}
																className={classes.addButton}
																onClick={() => addEvaluation(evaluator.consultant)}
																color="primary"
																variant="contained">
                                  Adicionar
															</Button>
															<Button 
																className={classes.button}
																onClick={() => handleClick(evaluator.consultant)}
																color="secondary" 
																variant="contained">
                                  Ver 
															</Button>
														</TableCell>														
													</TableRow>
												))}
											</TableBody>
										</Table>
									</div>
								</PerfectScrollbar>
							</CardContent>
							<CardActions className={classes.actions}>
								<TablePagination
									component="div"
									count={totalEvaluators}
									onChangePage={handlePageChange}
									page={page}
									labelDisplayedRows={({from, to, count}) => `${from}-${to} de ${count}`}
									rowsPerPage={10}
									rowsPerPageOptions={[10]}
								/>
							</CardActions>
						</Card>            
					</div>				
          </>:
          <>					  
					  	<Typography variant="h5">
              Não temos mais avaliadores disponíveis :(
					  	</Typography>					
          </>}											
				</DialogContent>        
			</Dialog>
		</div>
	);
}
