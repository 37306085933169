import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Link as RouterLink, withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import {	
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Button,
	Typography,
	Link,
	TextField,
} from "@material-ui/core";

import api from "../../services/api";
import { toast } from "react-toastify"; 

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
		height: "100%"
	},
	grid: {
		height: "100%"
	},
	quoteContainer: {
		[theme.breakpoints.down("md")]: {
			display: "none"
		}
	},  
	image: {
		width: 200,
		height: 180, 
		backgroundImage: "url(/images/logos/logo-fetec.png)",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		display: "block",
		marginLeft: "auto",
		marginRight: "auto"    
	},
	quote: {
		backgroundColor: theme.palette.neutral,
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundImage: "url(/images/bg.png)",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center"
	},
	quoteInner: {
		textAlign: "center",
		flexBasis: "600px"
	},
	quoteText: {
		color: theme.palette.white,
		fontWeight: 300
	},
	content: {
		height: "100%",
		display: "flex",
		flexDirection: "column"
	},
	formContent: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		maxWidth: "83.333333%"
	},
	contentHeader: {
		display: "flex",
		alignItems: "center",
		paddingTop: theme.spacing(5),
		paddingBototm: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2)
	},	
	contentBody: {
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		[theme.breakpoints.down("md")]: {
			justifyContent: "center"
		}
	},
	form: {
		paddingLeft: 100,
		paddingRight: 100,
		paddingBottom: 125,
		flexBasis: 700,
		[theme.breakpoints.down("sm")]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2)
		}
	},
	title: {
		marginTop: theme.spacing(3),
		paddingLeft: theme.spacing(2)
	},
	sendButton: {
		paddingLeft: theme.spacing(2)
	},
	leftSpace: {
		marginTop: theme.spacing(1),
		paddingLeft: theme.spacing(2)
	},
	dividerSpace: {
		marginLeft: theme.spacing(2)
	}
}));

const Password = (props) => {
	const classes = useStyles();  
  
	const { history } = props;

	const [open, setOpen] = React.useState(false);
	const [email, setEmail] = useState("");
	const [modal, setModal] = useState({
		title:"",
		message: ""
	});

	const handleClose = () => {
		setOpen(false);
		if(modal.title === "Sucesso!")
			history.push("/");
	};
  
	const handleSubmit = async (e) => {
		try {   
			e.preventDefault();
      
			await api.post("/users/recovery/password", {
				email
			});    			

			setModal({ title: "Sucesso!", message: "Verifique seu email, pois enviamos um link para que você possa criar uma nova senha!" });
			setOpen(true);
      
		} catch (err) {
			let message = "Nosso servidor está temporariamente indisponível, tente novamente mais tarde!";
			if (err.response) {
				message = err.response.data.userNotExists? "Não encontramos usuário com este e-mail em nossa base de dados.": message;
			}			
			
			setModal({title:"Atenção!", message});
			setOpen(true);
		}
	};

	return (
		<div className={classes.root}>
			<Grid className={classes.grid} container>
				<Grid
					className={classes.quoteContainer}
					item
					lg={5}
				>
					<div className={classes.quote}>
						<div className={classes.quoteInner}>
							<Typography
								className={classes.quoteText}
								variant="h1"
							>
                  Você é a partícula que nos move
							</Typography>							
						</div>
					</div>
				</Grid>
				<Grid item sm={6} xs={12} className={classes.formContent}>
					<div className={classes.content}>						
						<div className={classes.contentBody}>							
							<form className={classes.form} onSubmit={handleSubmit}>
								<Typography
									className={classes.title}
									variant="h2"
								>
								Recuperar a senha
								</Typography>
								<CardHeader
									subheader="Informe o email cadastrado para solicitar a recuperação da senha."										
								/>																
								<Divider className={classes.dividerSpace} />
								<CardContent>
									<TextField
										fullWidth
										label="E-mail"
										name="email"
										onChange={(e) => {setEmail(e.target.value);}}
										type="email"
										required
										value={email}
										variant="outlined"
									/>
					
								</CardContent>
								<Divider className={classes.dividerSpace} />
								<CardActions
									className={classes.sendButton}>					
									<Button
										color="primary"
										variant="outlined"
										disabled={!email}
										type="submit"
									>
										Enviar
									</Button>		
								</CardActions>
								<div className={classes.leftSpace}>
									<Typography
										color="textSecondary"
										variant="body1"
										>
											Lembrou da sua senha?{" "}
											<Link                 
												component={RouterLink}   		
												to="/" variant="h6">
										Faça o login.
										</Link>
									</Typography>
								</div>
							</form>							
						</div>
					</div>					
				</Grid>
			</Grid>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{modal.title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{modal.message}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary" autoFocus>
                      Ok
					</Button>
				</DialogActions>
			</Dialog>
      
		</div>
	
	);
};

export default Password;
