import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { signOut } from "../../../../store/modules/auth/actions";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, Badge, Hidden, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";

const useStyles = makeStyles(theme => ({
	root: {
		boxShadow: "none",
		backgroundColor: theme.palette.primary.main
	},
	flexGrow: {
		flexGrow: 1
	},
	signOutButton: {
		marginLeft: theme.spacing(1)
	}
}));

const Topbar = props => {
	const { className, onSidebarOpen, ...rest } = props;

	const classes = useStyles();
	const dispatch = useDispatch();

	const [notifications] = useState([]);
  
	function handleSignOut() {
		dispatch(signOut());
	}

	return (
		<AppBar
			{...rest}
			color="primary"
			className={clsx(classes.root, className)}
		>
			<Toolbar>
				<RouterLink to="/">					
					<img
						alt="Logo"
						src="/images/logos/tekoa-logo-branco.png"
						style={{height: 60}}
					/>
				</RouterLink>
				<div className={classes.flexGrow} />
				<Hidden mdDown>
					<IconButton color="inherit">
						<Badge
							badgeContent={notifications.length}
							color="primary"
							variant="dot"
						>
							<NotificationsIcon />
						</Badge>
					</IconButton>
					<IconButton       
						onClick={handleSignOut}             
						className={classes.signOutButton}
						color="inherit"
					>
						<InputIcon />
					</IconButton>
				</Hidden>
				<Hidden lgUp>
					<IconButton
						color="inherit"
						onClick={onSidebarOpen}
					>
						<MenuIcon />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
};

Topbar.propTypes = {
	className: PropTypes.string,
	onSidebarOpen: PropTypes.func
};

export default Topbar;
