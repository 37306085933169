import React from "react";
import * as _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	dialog: {
		width: "75%",
	},
	link: {
		color: theme.palette.primary.main
	},
	textMargin: {
		marginRight: theme.spacing(1)
	}
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={styles.root} {...other}>
			<Box mr={1}>
				<Typography variant="h6">{children}</Typography>
			</Box>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

export default function EvaluatorDialog(props) {  
	const { opened, setOpen, consultant } = props;	
	
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>			
			{consultant && (
				<Dialog className={styles.dialog} onClose={handleClose} aria-labelledby="customized-dialog-title" open={opened}>
					<DialogTitle id="customized-dialog-title" className={styles.textMargin} onClose={handleClose}>
          Avaliador: {consultant.user.name} - <Link color="secondary" className={styles.link} href={consultant.resume_link} target="_blank">
                Visualizar currículo Lattes
						</Link>       
					</DialogTitle>        
					<DialogContent dividers>
						<Typography variant="h6">Resumo profissional</Typography>
						<Typography gutterBottom>            
							{consultant.resume}
						</Typography>
						<Typography variant="h6">Titulação</Typography>
						<Typography gutterBottom>
							{_.capitalize(consultant.titration.description)}                      						  
						</Typography>
						<Typography variant="h6">Áreas de conhecimento</Typography>						
						{consultant.ExpertiseAreas.map((consultantArea) => (
							<Typography guttetBottom key={consultantArea.id} gutterBottom>  
								{`${consultantArea.area.description} - ${consultantArea.ExpertiseSubareas.map(x => x.subarea.description).join(", ")}`}</Typography>
						))}							

						<Typography variant="h6">Já participou de algum evento?</Typography>						
						<Typography gutterBottom>
							{consultant.EventParticipations.length === 0? 
								"Nenhum": consultant.EventParticipations.map(x => x.event.name).join(", ")}						
						</Typography>
            

						<Typography variant="h6">Informações para 2020</Typography>						
            
						{consultant.EventInfos.map((event) => (
							<Typography gutterBottom key={event.id}>{`Quantidade de projetos para avaliar: ${event.quantity_projects}` }</Typography>            
						))}						            
						<Typography variant="h6">Instituição</Typography>
						<Typography gutterBottom>
							{_.startCase(consultant.institution.description)}
						</Typography>
						<Typography variant="h6">Cidade/Estado</Typography>
						<Typography gutterBottom>
							{_.startCase(consultant.city.description)}/{_.startCase(consultant.state.description)}
						</Typography>
						<Typography gutterBottom>
						     
						</Typography>					
					</DialogContent>				
				</Dialog>
			)}			
		</div>
	);
}

EvaluatorDialog.propTypes = {
	opened: PropTypes.bool,
	setOpen: PropTypes.func,
	consultant: PropTypes.object
};
