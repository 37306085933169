import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import {	
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
	Button,
	TextField,
	Typography,
} from "@material-ui/core";
import api from "../../../../services/api";
import { toast } from "react-toastify"; 

const useStyles = makeStyles(() => ({
	root: {},
	errorText: {
		marginTop: "1rem",
		border: "1px solid #f44336",
		padding: 5,
		color: "#f44336"
	},
	passwordInput: {
		marginTop: "1rem"
	}
}));

const Password = props => {
	const { className, ...rest } = props;

	const userState = useSelector((state) => state.user.profile);
	const classes = useStyles();  

	const [values, setValues] = useState({
		password: "",
		confirm: "",
		oldPassword: ""
	});
  
	const updateButtonDisabled = values.password.length < 6 || values.confirm.length < 6   
   || values.password != values.confirm;

	const handleChange = event => {
		setValues({
			...values,
			[event.target.name]: event.target.value
		});
	};
  
	const handleClick = async () => {
		try {      
			await api.put(`users/${userState.id}`, {
				oldPassword: values.oldPassword,
				password: values.password,
				confirmPassword: values.confirm
			});
			toast.success("Senha alterada com sucesso");
		} catch (err) {
			toast.error("Ops! Ocorreu um erro ao tentar mudar sua senha.");
		}
	};

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<form>
				<CardHeader
					subheader="Atualizar a senha"
					title="Senha"
				/>
				<Divider />
				<CardContent>
					<TextField
						fullWidth
						label="Antiga senha*"
						name="oldPassword"
						onChange={handleChange}
						type="password"
						value={values.oldPassword}
						variant="outlined"
					/>
					<TextField
						className={classes.passwordInput}
						fullWidth
						label="Nova senha* (mínimo de 6 caracteres)"
						name="password"
						onChange={handleChange}
						type="password"
						value={values.password}
						variant="outlined"
					/>
					<TextField
						className={classes.passwordInput}
						fullWidth
						label="Confirmar nova senha* (mínimo de 6 caracteres)"
						name="confirm"
						onChange={handleChange}						
						type="password"
						value={values.confirm}
						variant="outlined"
					/>
					{updateButtonDisabled && (
            <>
            {values.password.length < 6 && values.confirm.length < 6 && (
            	<Typography className={classes.errorText} variant="h6">
							 A nova senha deve ter no mínimo 6 caracteres							              					
					    </Typography>
            )}						
            {values.password !== values.confirm && (
            	<Typography className={classes.errorText} variant="h6">
            	As senhas estão diferentes
            	</Typography>
            )}            
            </>
					)}          
				</CardContent>
				<Divider />
				<CardActions>					
					<Button
						color="primary"
						variant="outlined"
						disabled={updateButtonDisabled}
						onClick={handleClick}
					>
            Atualizar
					</Button>					
				</CardActions>
			</form>
		</Card>
	);
};

Password.propTypes = {
	className: PropTypes.string
};

export default Password;
