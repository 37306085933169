import React, { useState, useEffect } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import validate from "validate.js";
import api from "../../services/api";

import {
	Grid,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	Link,
	MenuItem,
	FormControl,
	FormControlLabel,
	FormGroup,
	Checkbox,
	Typography
} from "@material-ui/core";

import { Rabbit as ButtonLoad } from "react-button-loaders";

import useStyles from "./styles";
import schema from "./schema";

const SignUp = props => {
	const { history } = props;

	const classes = useStyles();

	const [open, setOpen] = React.useState(false);
	const [steps, setSteps] = useState(1);
	const [buttonLoading, setButtonLoading] = useState("default");
  
	const [ufs, setUfs] = useState([]);
	const [cities, setCities] = useState([]);
	const [institutions, setInstitutions] = useState([]);
	const [titrations, setTitrations] = useState([]);
	const [events, setEvents] = useState([]);
	const [areas, setAreas] = useState([]);
	const [primarySubAreas, setPrimarySubAreas] = useState([]);
	const [secondarySubAreas, setSecondarySubAreas] = useState([]);
	const [thirdSubAreas, setThirdSubAreas] = useState([]);
	const [quantityProjectsEvaluate, setQuantityProjectsEvaluate] = useState("");

	const [modal, setModal] = useState({
		title:"",
		message: ""
	});
  
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		cpf: "",
		phone: "",
		resume: "",
		resume_link: "",
		password: "",
		confirmPassword: ""
	});

	const [formState, setFormState] = useState({
		isValid: false,
		values: {},
		touched: {},
		errors: {}
	});

	const [selectedUf, setSelectedUf] = useState("0");
	const [selectedCity, setSelectedCity] = useState("0");
	const [selectedInstitution, setSelectedInstitution] = useState("0");
	const [selectedTitration, setSelectedTitration] = useState("0");
	const [selectedPrimaryArea, setSelectedPrimaryArea] = useState("0");
	const [selectedSecondaryArea, setSelectedSecondaryArea] = useState("0");
	const [selectedThirdArea, setSelectedThirdArea] = useState("0");

	const [selectedPrimarySubAreas, setSelectedPrimarySubAreas] = useState([]);
	const [selectedSecondarySubAreas, setSelectedSecondarySubAreas] = useState([]);
	const [selectedThirdSubAreas, setSelectedThirdSubAreas] = useState([]);
	const [selectedParticipations, setSelectedParticipations] = useState([]);

	//Load states
	useEffect(() => {
		api.get("states").then(res => {
			setUfs(res.data);
		});
	}, []);

	//Load titrations
	useEffect(() => {
		api.get("titrations").then(res => {
			setTitrations(res.data);
		});
	}, []);

	//Load areas
	useEffect(() => {
		api.get("areas").then(res => {
			setAreas(res.data);
		});
	}, []);
  
	useEffect(() => {
		api.get("events/deactivated").then(res => {
			setEvents(res.data);
		});
	}, []);

	//Load Sub Areas by Area selected
	useEffect(() => {
		
		if (selectedPrimaryArea === "0")
			return;
      
		setSelectedPrimarySubAreas([]);
    
		api.get(`sub-areas/${selectedPrimaryArea}`).then(res => {
			setPrimarySubAreas(res.data);
		});
		
	}, [selectedPrimaryArea]);

	useEffect(() => {
		
		if (selectedSecondaryArea === "0")
			return;

		setSelectedSecondarySubAreas([]);
    
		api.get(`sub-areas/${selectedSecondaryArea}`).then(res => {
			setSecondarySubAreas(res.data);
		});
		
	}, [selectedSecondaryArea]);
  
	useEffect(() => {
		
		if (selectedThirdArea === "0")
			return;

		setSelectedThirdSubAreas([]);
    
		api.get(`sub-areas/${selectedThirdArea}`).then(res => {
			setThirdSubAreas(res.data);
		});
		
	}, [selectedThirdArea]);
	
	useEffect(() => {

		async function getData() {
			if (selectedUf === "0")
				return;

			const [returnedCities, returnedInstitutions] = await Promise.all([
				api.get(`cities/${selectedUf.id}`),
				api.get(`institutions/${selectedUf.id}`),
			]);

			setInstitutions(returnedInstitutions.data);
			setCities(returnedCities.data);
		}

		getData();

	}, [selectedUf]);

	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState(formState => ({
			...formState,
			isValid: errors ? false : true,
			errors: errors || {}
		}));
	}, [formState.values]);

	function CpfMask(v) {
		v = v.replace(/\D/g, "");
		v = v.replace(/(\d{3})(\d)/, "$1.$2");
		v = v.replace(/(\d{3})(\d)/, "$1.$2");
		v = v.replace(/(\d{3})(\d{1,2})/, "$1-$2");
		v = v.replace(/(-\d{2})\d+?$/, "$1");
		return v;
	}

	function phoneMask(v) {
		v = v.replace(/\D/g, "");
		v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
		v = v.replace(/(\d)(\d{4})$/, "$1-$2");
		return v;
	}

	function handleCpf(e) {
		const cpf = CpfMask(e.target.value);
		setFormData({
			...formData,
			cpf
		});
	}

	function handlePhone(e) {
		let phone = e.target.value;
		if (phone.length === 16)
			return;
		phone = phoneMask(e.target.value);
		setFormData({
			...formData,
			phone
		});
	}
  
	function handleClose(){
		setOpen(false);
		if(modal.title === "Sucesso!")
			history.push("/");
	};
  
	function handleSelectUf(e, newValue) {		
		const uf = newValue;
		setSelectedUf(uf);
	}

	function handleSelectTitration(e) {
		const titration = e.target.value;
		setSelectedTitration(titration);
	}

	function handleSelectCity(e, newValue) {
		const city = newValue;
		setSelectedCity(city);
	}

	function handleSelectInstitution(e, newValue) {
		const institution = newValue;
		setSelectedInstitution(institution);
	}

	function handlePrimaryArea(e) {
		const area = e.target.value;
		setSelectedPrimaryArea(area);
	}

	function handleSecondaryArea(e) {
		const area = e.target.value;
		setSelectedSecondaryArea(area);
	}
  
	function handleThirdArea(e) {
		const area = e.target.value;
		setSelectedThirdArea(area);
	}
  
	function handleQuantityProjectsEvaluate(e) {
		const answer = e.target.value;
		setQuantityProjectsEvaluate(answer);
	}

	function handleCheckBoxPrimarySubAreaChange(e) {
		
		const { value } = e.target;
		
		const found = selectedPrimarySubAreas.findIndex(element => element === value);
    
		if(found === -1){
			setSelectedPrimarySubAreas([...selectedPrimarySubAreas, value]);
		}else{
			const subAreas = selectedPrimarySubAreas;
			subAreas.splice(found, 1);	
			setSelectedPrimarySubAreas([...subAreas]);
		}
	}
  
	function handleCheckBoxSecondarySubAreaChange(e) {
		
		const { value } = e.target;
    
		const found = selectedSecondarySubAreas.findIndex(element => element === value);
    
		if(found === -1){
			setSelectedSecondarySubAreas([...selectedSecondarySubAreas, value]);
		}else{
			const subAreas = selectedSecondarySubAreas;
			subAreas.splice(found, 1);	
			setSelectedSecondarySubAreas([...subAreas]);
		}
	}
  
	function handleCheckBoxThirdSubAreaChange(e) {
		
		const { value } = e.target;
    
		const found = selectedThirdSubAreas.findIndex(element => element === value);
    
		if(found === -1){
			setSelectedThirdSubAreas([...selectedThirdSubAreas, value]);
		}else{
			const subAreas = selectedThirdSubAreas;
			subAreas.splice(found, 1);	
			setSelectedThirdSubAreas([...subAreas]);
		}
	}
  
	function handleCheckBoxParticipationsChange(event) {			  
		const found = selectedParticipations.findIndex(element => element.name === event.name);
     
		if(found === -1){
			setSelectedParticipations([...selectedParticipations, {...event}]);
		} else {
			const participations = selectedParticipations;
			participations.splice(found, 1);	
			setSelectedParticipations([...participations]);
		}  		
	}

	function handleInputChange(e) {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value
		});
	}

	async function handleSubmit(e) {
		e.preventDefault();
		const {
			name,
			email,
			cpf,
			phone,
			resume,
			resume_link,
			password,
			confirmPassword
		} = formData;

		if (password.length < 6){
			setModal({title:"Atenção!", message:"A sua senha deve ter, pelo menos, 6 caracteres."});
			setOpen(true);
			return;
		}
    
		if (password !== confirmPassword){
			setModal({title:"Atenção!", message:"As senhas não coincidem."});
			setOpen(true);
			return;
		}
	
		setButtonLoading("loading");
    
		const state_id = selectedUf.id;
		const city_id = selectedCity.id;
		const institution_id = selectedInstitution.id;
		const titration_id = selectedTitration;
		const area_id = selectedPrimaryArea;

		let areas = [
			{
				id: selectedPrimaryArea,
				subareas: [
					...selectedPrimarySubAreas
				]
			}
		];

		if(selectedSecondarySubAreas.length > 0)
			areas.push({
				id: selectedSecondaryArea,
				subareas: [
					...selectedSecondarySubAreas
				]
			});
      
		if(selectedThirdSubAreas > 0)
			areas.push({
				id: selectedThirdArea,
				subareas: [
					...selectedThirdSubAreas
				]
			});
				
		const event = {
			quantityProjectsEvaluate,
			previusParticipations: selectedParticipations
		};

		const data = {
			user:{
				name,
				password,
				email
			},
			cpf,
			phone,
			resume,
			resume_link,
			country_id: 1,
			state_id,      
			city_id,
			institution_id,
			titration_id,
			area_id,
			areas,
			event
		};
    		
		await api.post("/consultants", data)
			.then(function (_) {
				setButtonLoading("completed");
				setModal({title:"Sucesso!", message:"O seu cadastro foi criado com sucesso e está pendente de aprovação para a segunda etapa de avaliação!"});
				setOpen(true);
				return;
			})
			.catch(function (err) {
				if(err.response.data.userExists)
					setModal({ title: "Atenção!", message: "O Email ou CPF informado já está cadastrado!" });
				else
					setModal({title:"Atenção!", message:"Nosso servidor está temporáriamente indisponível, tente novamente mais tarde!"});
				setOpen(true);
				setButtonLoading("default");
			});
		return;

	}
  
	function validateForm(){		
		switch(steps){
		case 1:
			if(
				formData.name.length >= 5 && 
        formData.cpf.length === 14 && 
        formData.phone.length > 13 &&
        selectedUf !== "0" &&
        selectedCity !== "0" &&
        selectedUf !== null &&
        selectedCity !== null 
			)
				return false;
			return true;
      
		case 2:
			if(
				selectedInstitution !== "0" &&
        selectedInstitution !== null &&
        selectedTitration !== "0" &&
        formData.resume_link.length > 5 && 
        formData.resume.length > 20
			)
				return false;
			return true;
      
		case 3:
			if(
				selectedPrimaryArea !== "0" &&
        selectedPrimarySubAreas.length > 0 
			)
				return false;
			return true;
    
		case 4:
			return false;
      
		case 5:
			return false;
      
		case 6:
			if(
				selectedParticipations.length > 0
			)
				return false;
			return true;
      
		case 7:
			if(
			  quantityProjectsEvaluate.length > 0
			)
				return false;
			return true;
      
		default:
			return true;
		}
	}

	return (
	  <div className={classes.root}>
			<Grid
				className={classes.grid}
				container
			>
				<Grid
					className={classes.quoteContainer}
					item
					lg={5}
				>
					<div className={classes.quote}>
						<div className={classes.quoteInner}>
							<Typography
								className={classes.quoteText}
								variant="h1"
							>
                Uma janela para a revolução do ensino básico brasileiro
							</Typography>							
						</div>
					</div>
				</Grid>
				<Grid
					className={classes.content}
					item
					lg={7}
					xs={12}
				>
					<div className={classes.content}>

						<div className={classes.contentBody}>

							<form
								autoComplete="no"
								className={classes.form}
								onSubmit={handleSubmit}
							>

								<Typography
									className={classes.title}
									variant="h2"
								>
								Cadastre-se:
								</Typography>

								{steps === 1 && 
                  <>
                    <TextField
                    	className={classes.textField}
                    	fullWidth
                    	label="Nome completo"
                    	required
                    	name="name"
                    	onChange={handleInputChange}
                    	type="text"
                    	value={formData.name || ""}
                    	variant="outlined"
                    />

                    <TextField
                    	className={classes.textField}
                    	fullWidth
                    	label="CPF"
                    	required
                    	name="cpf"
                    	onChange={handleCpf}
                    	type="text"
                    	value={formData.cpf || ""}
                    	variant="outlined"
                    />

                    <TextField
                    	className={classes.textField}
                    	fullWidth
                    	label="Telefone"
                    	required
                    	name="phone"
                    	onChange={handlePhone}
                    	type="text"
                    	value={formData.phone || ""}
                    	variant="outlined"
                    />

                    <div>
                    	<Autocomplete					
                    		id="state_id"					       										
                    		options={ufs}
                    		style={{width: "100%"}}										
                    		getOptionLabel={(option) => option.initials}			                    
                    		getOptionSelected={(option) => option.id}
                    		onChange={handleSelectUf}
                    		value={selectedUf}							
                    		renderInput={(params) => {
                    			params.inputProps.autoComplete="no";
                    			return <TextField {...params} label="Estado" className={classes.textField} required fullWidth variant="outlined" />;
                    		}}
                    	/>		 
                    	<Autocomplete					
                    		id="city_id"					       										
                    		options={cities}
                    		style={{width: "100%"}}										
                    		getOptionLabel={(option) => option.description}			                    
                    		getOptionSelected={(option) => option.id}
                    		onChange={handleSelectCity}
                    		value={selectedCity}							
                    		renderInput={(params) => {
                    			params.inputProps.autoComplete="no";
                    			return <TextField {...params} label="Cidade" className={classes.textField} required fullWidth variant="outlined" />;
                    		}}
                    	/>		                     
                    </div>
                    
                    <div style={{marginTop:"10px"}}>
                    	<Typography
                    	color="textSecondary"
                    	variant="body1"
                    	>
								      Já possui cadastro?{" "}
                    	<Link
                    		component={RouterLink}
                    		to="/"
                    		variant="h6"
                    	>
									     Faça o Login.
                    	</Link>
                    	</Typography>
                    </div>
                  </>
								}

								{steps === 2 && 
                  <>
                  	<Autocomplete					
                    		id="institution_id"					       										
                    		options={institutions}
                    		style={{width: "100%"}}										
                    		getOptionLabel={(option) => option.description}			                    
                    		getOptionSelected={(option) => option.id}
                    		onChange={handleSelectInstitution}
                    		value={selectedInstitution}							
                    		renderInput={(params) => {
                    			params.inputProps.autoComplete="no";
                    			return <TextField {...params} label="Instituição" className={classes.textField} required fullWidth variant="outlined" />;
                    		}}
                    	/>		                                         

                    <TextField
                    	className={classes.textField}
                    	name="titration_id"
                    	select
                    	label="Titulação"
                    	required
                    	value={selectedTitration}
                    	onChange={handleSelectTitration}
                    	variant="outlined"
                    	fullWidth
                    >

                    	{titrations.map(titration => (
                    		<MenuItem key={titration.id} value={titration.id}>{titration.description}</MenuItem>
                    	))}

                    </TextField>

                    <TextField
                    	className={classes.textField}
                    	fullWidth
                    	label="Inserir endereço do currículo Lattes"
                    	required
                    	name="resume_link"
                    	onChange={handleInputChange}
                    	type="text"
                    	value={formData.resume_link || ""}
                    	variant="outlined"
                    />

										<TextField
											className={classes.textField}
											label="Resumo Profissional (No mínimo 20 caracteres)*"
											fullWidth
											multiline
											rows={4}
											name="resume"
											onChange={handleInputChange}
											value={formData.resume || ""}
											variant="outlined"
										/>
                  </>
								} 

								{ steps === 3 && 
                  <>        
                    <TextField
                    	className={classes.textField}
                    	name="primary_area_id"
                    	select
                    	label="Área de Atuação (Principal)"
                    	required
                    	value={selectedPrimaryArea}
                    	onChange={handlePrimaryArea}
                    	variant="outlined"
                    	fullWidth
                    >
                    	{areas.map(area => (
                    		<MenuItem key={area.id} value={area.id}>{area.description}</MenuItem>

                    	))}
                    </TextField>

                    {
                    	primarySubAreas.length > 0 && <FormControl component="fieldset" className={classes.textField}>
                    		<span className={classes.subtitle}>Selecione até 3 subáreas:</span>
                    		<FormGroup className={classes.checkbox}>
                    			{primarySubAreas.map(subArea => (
                    				<FormControlLabel
                    					key={subArea.id}
                    					control={
                    						<Checkbox 
                    							checked={selectedPrimarySubAreas.includes(String(subArea.id))} 
                    							onClick={handleCheckBoxPrimarySubAreaChange} 
                    							name={String(subArea.id)} 
                    						/>
                    					}
                    					value={subArea.id}
                    					label={subArea.description}
                    				/>
                    			))}
                      
                    		</FormGroup>
                    	</FormControl>
                    }
							    </>
								}  

								{ steps === 4 && 
                  <>   
                    <TextField
                    	className={classes.textField}
                    	name="secondary_area_id"
                    	select
                    	label="Área de Atuação (2ª opção - Não obrigatória)"
                    	required
                    	value={selectedSecondaryArea}
                    	onChange={handleSecondaryArea}
                    	variant="outlined"
                    	fullWidth
                    >
                    	{areas.filter(x => x.id !== selectedPrimaryArea).map(area => (
                    		<MenuItem key={area.id} value={area.id}>{area.description}</MenuItem>

                    	))}
                    </TextField>

                    {
                    	secondarySubAreas.length > 0 && <FormControl component="fieldset" className={classes.textField}>
                    	  <span className={classes.subtitle}>Selecione as subáreas:</span>
                    		<FormGroup className={classes.checkbox}>
                    			{secondarySubAreas.map(subArea => (
                    				<FormControlLabel
                    					key={subArea.id}
                    					control={
                    						<Checkbox 
                    							checked={selectedSecondarySubAreas.includes(String(subArea.id))} 
                    							onChange={handleCheckBoxSecondarySubAreaChange} 
                    							name={String(subArea.id)} 
                    						/>
                    					}
                    					value={subArea.id}
                    					label={subArea.description}
                    				/>
                    			))}
                      
                    		</FormGroup>
                    	</FormControl>
                    }
								  </>
								}

								{ steps === 5 && 
                  <>   
                    <TextField
                    	className={classes.textField}
                    	name="third_area_id"
                    	select
                    	label="Área de Atuação (3ª opção - Não obrigatória)"
                    	value={selectedThirdArea}
                    	onChange={handleThirdArea}
                    	variant="outlined"
                    	fullWidth
                    >
                    	{areas.filter(x => x.id !== selectedPrimaryArea && x.id !== selectedSecondaryArea).map(area => (
                    		<MenuItem key={area.id} value={area.id}>{area.description}</MenuItem>

                    	))}
                    </TextField>

                    {
                    	thirdSubAreas.length > 0 && <FormControl component="fieldset" className={classes.textField}>
                    	  <span className={classes.subtitle}>Selecione as subáreas:</span>
                    		<FormGroup className={classes.checkbox}>
                    			{thirdSubAreas.map(subArea => (
                    				<FormControlLabel
                    					key={subArea.id}
                    					control={
                    						<Checkbox 
                    							checked={selectedThirdSubAreas.includes(String(subArea.id))} 
                    							onChange={handleCheckBoxThirdSubAreaChange} 
                    							name={String(subArea.id)} 
                    						/>
                    					}
                    					value={subArea.id}
                    					label={subArea.description}
                    				/>
                    			))}
                      
                    		</FormGroup>
                    	</FormControl>
                    }
								  </>
								}

								{ steps === 6 && 
                  <>  
                    <FormControl component="fieldset" className={classes.textField}>
                    	<p className={classes.subtitle}>Marque a(s) edição(ões) da qual já participou como avaliador:</p>
                    	<FormGroup className={classes.checkbox}>
                    		{events.map((event) => (
                    			<FormControlLabel key={event.id}
                    			  control={
                    				  <Checkbox 
                    						disabled={selectedParticipations.find(x => x.name === "Nenhuma")} 
                    						checked={selectedParticipations.find(x => x.name === event.name)} 
                    						onChange={() => handleCheckBoxParticipationsChange(event)}               
                    				  />
                    			}
                    			label={event.name}
                    			value={event.name}
                    		/>
                    		))}                    		                    		
                    		<FormControlLabel
                    			control={
                    				<Checkbox 
                    					disabled={selectedParticipations.length > 0 && !selectedParticipations.find(x => x.name === "Nenhuma")}
                    					checked={selectedParticipations.find(x => x.name ===  "Nenhuma")} 
                    					onChange={() => handleCheckBoxParticipationsChange({name: "Nenhuma"})}  
                    				/>
                    			}
                    			label="Nenhuma"
                    			value="Nenhuma"
                    		/>
                    
                    	</FormGroup>
                    </FormControl>
                  </>
								}

								{ steps === 7 && 
                  <>
                    <TextField
                    	className={classes.textField}
                    	name="quantityProjectsEvaluate"
                    	select
                    	label="Quantos projetos você poderia avaliar?"
                    	required
                    	value={quantityProjectsEvaluate}
                    	onChange={handleQuantityProjectsEvaluate}
                    	variant="outlined"
                    	fullWidth
                    >

                    	<MenuItem value="de 1 a 3 projetos">de 1 a 3 projetos</MenuItem>
                    	<MenuItem value="de 4 a 7 projetos">de 4 a 7 projetos</MenuItem>
                    	<MenuItem value="de 8 a 12 projetos">de 8 a 12 projetos</MenuItem>
                    	<MenuItem value="de 13 a 20 projetos">de 13 a 20 projetos</MenuItem>

                    </TextField>
                  </>
								}

								{ steps === 8 && 
                  <>
                    <TextField
                    	className={classes.textField}
                    	fullWidth
                    	label="Email"
                    	required
                    	name="email"
                    	onChange={handleInputChange}
                    	type="text"
                    	value={formData.email || ""}
                    	variant="outlined"
                    />

                    <TextField
                    	className={classes.textField}
                    	fullWidth
                    	label="Senha"
                    	required
                    	name="password"
                    	onChange={handleInputChange}
                    	type="password"
                    	value={formData.password || ""}
                    	variant="outlined"
                    />

                    <TextField
                    	className={classes.textField}
                    	fullWidth
                    	label="Confirme a Senha"
                    	required
                    	name="confirmPassword"
                    	onChange={handleInputChange}
                    	type="password"
                    	value={formData.confirmPassword || ""}
                    	variant="outlined"
                    />
                    <ButtonLoad 
                    	type="submit" 
                    	className={classes.signUpButton}                     	                    	
                    	bgLoading={"#ff9800"} 
                    	state={buttonLoading}
                    >
                      Cadastrar
                    </ButtonLoad>
                  </>
								}

								<div className={classes.buttonStep}>
									<Button
										className={classes.buttonStepStyle}
										color="primary"
										disabled={steps === 1 || buttonLoading!=="default"}
										size="large"
										onClick={() => setSteps(steps-1)}
										variant="contained"
									>
								    Anterior
									</Button>

									{ steps < 8 &&
                    <Button
                    	className={classes.buttonStepStyle}
                    	disabled={validateForm()}
                    	color="primary"
                    	size="large"
                    	onClick={() => setSteps(steps+1)}
                    	variant="contained"
                    >
                      Próximo
                    </Button>
									}
								</div>

								<Dialog
									open={open}
									onClose={handleClose}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
								>
									<DialogTitle id="alert-dialog-title">{modal.title}</DialogTitle>
									<DialogContent>
										<DialogContentText id="alert-dialog-description">
											{modal.message}
										</DialogContentText>
									</DialogContent>
									<DialogActions>
										<Button onClick={handleClose} color="primary" autoFocus>
                      Ok
										</Button>
									</DialogActions>
								</Dialog>
							</form>
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

SignUp.propTypes = {
	history: PropTypes.object
};

export default withRouter(SignUp);
