import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import api from "../../services/api";
import history from "../../services/history";
import PerfectScrollbar from "react-perfect-scrollbar";
import { SearchInput } from "components";
import {  
	Button,
	Tooltip,
	Card,
	CardActions,
	CardContent,	
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	CircularProgress,
	TablePagination
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		flex: 1		
	},
	content: {
		marginTop: theme.spacing(2),		     
		justifyContent: "center"		
	},	
	inner: {
		minWidth: 800
	},
	nameContainer: {
		display: "flex",
		alignItems: "center"
	},
	avatar: {
		marginRight: theme.spacing(2)
	},
	avatarCheckIcon: {
		backgroundColor: theme.palette.success.main,
		height: 22,
		width: 22,
		marginLeft: 5
	},
	avatarCancelIcon: {
		backgroundColor: theme.palette.error.main,
		height: 22,
		width: 22,
		marginLeft: 5
	},
	checkIcon: {
		height: 14,
		width: 14
	},
	cancelIcon: {
		height: 14,
		width: 14
	},
	actions: {
		justifyContent: "flex-end"
	},
	button: {
		backgroundColor: theme.palette.primary.main
	},
	row: {
		height: "42px",
		display: "flex",
		alignItems: "center",
		marginTop: theme.spacing(1)
	},
	spacer: {
		flexGrow: 1
	},
	importButton: {
		marginRight: theme.spacing(1)
	},
	exportButton: {
		marginRight: theme.spacing(1)
	},
	searchInput: {
		marginRight: theme.spacing(1)
	}	
}));

const UserProjectList = () => {
	const classes = useStyles();

	const [projects, setProjects] = useState([]);
	const [totalProjects, setTotalProjects] = useState(0);	
	const [page, setPage] = useState(0);  	
	const [loading, setLoading] = useState(true);	
	const [search, setSearch] = useState("");		
  
	const handlePageChange = (event, page) => {		
		setPage(page);
	};	

	async function loadProjects() {    
		setLoading(true);
		const response = await api.get("projects/my", {
			params: { page: page + 1, search },
		});  		

		setProjects(response.data.projects);
		setTotalProjects(response.data.total);
		setLoading(false);
	}
  
	const handleClick = (id) => {
		history.push(`/projects/${id}`);
	};
  
	const handleFinalStep = (id) => {
		history.push(`/projects/${id}/final`);
	};
  
	useEffect(() => {
		loadProjects();
	}, [page]);	
  
	useEffect(() => {
		loadProjects();
	}, [search]);
  

	useEffect(() => {		
		loadProjects(0);
	},[]);
  

	return (
		<div className={classes.root}>			
			<div className={classes.row}>
				<SearchInput
					className={classes.searchInput}
					placeholder="Procurar projeto por título"
					value={search}
					onChange={(event) => setSearch(event.target.value)}
				/>				
			</div>			
      	<div className={classes.content}>
				<Card
					className={classes.root}
				>
					<CardContent className={classes.content}>				
						{loading?                        
							<CircularProgress/>:						
							<PerfectScrollbar>
								<div className={classes.inner}>
									<Table>
										<TableHead>
											<TableRow>									
												<TableCell>Título</TableCell>									
												<TableCell>Área</TableCell>									
												<TableCell>Alunos</TableCell>																					
												<TableCell></TableCell>							
											</TableRow>
										</TableHead>
										<TableBody>
											{projects.map(project => (
												<TableRow
													className={classes.tableRow}
													hover
													key={project.id}										
												>																		
													<TableCell>
														<div className={classes.nameContainer}>												
															<Typography variant="body1">{project.title}</Typography>												
														</div>
													</TableCell>					
													<TableCell>
														{project.area.description} - {project.subarea.description}													
													</TableCell>
													<TableCell>
														{(project.students || []).map(({ student }) => (
															<p key={student.id}>{(student.user || {}).name}</p>
														))}
													</TableCell>													
													<TableCell>
														<div>														
															<Tooltip title={"Clique para revisar um projeto"}>															
																<Button 																	
																	color="primary" 
																	variant="contained"
																	onClick={() => handleClick(project.id)}>
                                  Editar
																</Button>															
															</Tooltip>			
															<Tooltip title={"Clique para submeter os últimos arquivos"}>															
																<Button
																	style={{ marginLeft: "10px" }}                                                                   		                                  															
																	color="secondary" 
																	variant="contained"
																	onClick={() => handleFinalStep(project.id)}>
                                  Etapa final
																</Button>															
															</Tooltip>			
														</div>								
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</div>
							</PerfectScrollbar>
						}</CardContent>
					<CardActions className={classes.actions}>
						<TablePagination
							component="div"
							count={totalProjects}
							onChangePage={handlePageChange}
							page={page}
							labelDisplayedRows={({from, to, count}) => `${from}-${to} de ${count}`}
							rowsPerPage={10}
							rowsPerPageOptions={[10]}
						/>
					</CardActions>
				</Card>
			</div>
		</div>
	);
};

export default UserProjectList;
