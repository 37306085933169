import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import api from "../../services/api";
import history from "../../services/history";
import PerfectScrollbar from "react-perfect-scrollbar";
import {  
	Button,
	Tooltip,
	Card,
	CardActions,
	CardContent,	
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	CircularProgress,
	TablePagination
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		flex: 1		
	},
	content: {
		marginTop: theme.spacing(2),		     
		justifyContent: "center"		
	},	
	inner: {
		minWidth: 800
	},
	nameContainer: {
		display: "flex",
		alignItems: "center"
	},
	avatar: {
		marginRight: theme.spacing(2)
	},
	avatarCheckIcon: {
		backgroundColor: theme.palette.success.main,
		height: 22,
		width: 22,
		marginLeft: 5
	},
	avatarCancelIcon: {
		backgroundColor: theme.palette.error.main,
		height: 22,
		width: 22,
		marginLeft: 5
	},
	checkIcon: {
		height: 14,
		width: 14
	},
	cancelIcon: {
		height: 14,
		width: 14
	},
	actions: {
		justifyContent: "flex-end"
	},
	button: {
		backgroundColor: theme.palette.primary.main
	},
	row: {
		height: "42px",
		display: "flex",
		alignItems: "center",
		marginTop: theme.spacing(1)
	},
	spacer: {
		flexGrow: 1
	},
	importButton: {
		marginRight: theme.spacing(1)
	},
	exportButton: {
		marginRight: theme.spacing(1)
	},
	searchInput: {
		marginRight: theme.spacing(1)
	},
	evaluationButton: {
		whiteSpace: "nowrap"
	}	
}));

const ProjectResultList = () => {
	const classes = useStyles();	

	const [evaluations, setEvaluations] = useState([]);
	const [totalEvaluations, setTotalEvaluations] = useState(0);	
	const [page, setPage] = useState(0);  	
	const [loading, setLoading] = useState(true);	
  
	const handlePageChange = (event, page) => {		
		setPage(page);
	};	

	async function loadEvaluations() {    
		setLoading(true);
		const response = await api.get("projects/evaluations", {
			params: { page: page + 1 },
		});  		

		setEvaluations(response.data.evaluations);
		setTotalEvaluations(response.data.total);
		setLoading(false);
	}
  
	const handleClick = (id) => {
		history.push(`/projects/${id}/evaluations`);
	};
  
	useEffect(() => {
		loadEvaluations();
	}, [page]);	  

	useEffect(() => {		
		loadEvaluations(0);
	},[]);

	return (
		<div className={classes.root}>						
			<div className={classes.content}>
				<Card
					className={classes.root}
				>
					<CardContent className={classes.content}>				
						{loading?                        
							<CircularProgress/>:						
							<PerfectScrollbar>
								<div className={classes.inner}>
									<Table>
										<TableHead>
											<TableRow>									
												<TableCell>Título</TableCell>									
												<TableCell>Área</TableCell>																							
												<TableCell></TableCell>							
											</TableRow>
										</TableHead>
										<TableBody>
											{evaluations.map(project => (
												<TableRow
													className={classes.tableRow}
													hover
													key={project.id}										
												>																		
													<TableCell>
														<div className={classes.nameContainer}>												
															<Typography variant="body1">{project.title}</Typography>												
														</div>
													</TableCell>					
													<TableCell size="small">
														{project.area} - {project.subarea}													
													</TableCell>										
													<TableCell>
														<div>														
															<Tooltip title={"Clique para visualizar o parecer"}>															
																<Button 
																	className={classes.evaluationButton}
																	color="primary" 
																	variant="contained"
																	onClick={() => handleClick(project.id)}>
                                Ver parecer
																</Button>															
															</Tooltip>			
														</div>								
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</div>
							</PerfectScrollbar>
						}</CardContent>
					<CardActions className={classes.actions}>
						<TablePagination
							component="div"
							count={totalEvaluations}
							onChangePage={handlePageChange}
							page={page}
							labelDisplayedRows={({from, to, count}) => `${from}-${to} de ${count}`}
							rowsPerPage={10}
							rowsPerPageOptions={[10]}
						/>
					</CardActions>
				</Card>
			</div>
		</div>
	);
};

export default ProjectResultList;
