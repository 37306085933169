import { all, takeLatest, call, put } from "redux-saga/effects";

import { toast } from "react-toastify";

import api from "../../../services/api";

import { updateProfileSuccess, updateProfileFailure, updateAvatarSuccess, updateUserSuccess } from "./actions";

export function* updateProfile({ payload }) {
	try {
		const { name, email, avatar_id, ...rest } = payload.data;

		const profile = {
			name,
			email,
			avatar_id,
			...(rest.oldPassword ? rest : {}),
		};

		const response = yield call(api.put, "users", profile);

		toast.success("Perfil atualizado com sucesso");

		yield put(updateProfileSuccess(response.data));
	} catch (error) {
		toast.error("Erro ao atualizar perfil, confira seus dados!");
		yield put(updateProfileFailure());
	}
}

export function* updateAvatar({ payload }) {
	try {
		const { data } = payload;
    
		const response = yield call(api.post, "users/avatars", data);

		toast.success("Eba! Agora você tem uma nova foto.");

		yield put(updateAvatarSuccess(response.data.location));
	} catch (error) {
		if (error.response && error.response.data.msg) {
			return toast.error(error.response.data.msg);
		}    

		toast.error("Ops! Tivemos um erro ao tentar atualizar sua foto.");
	}
}

export function* updateUser({ payload }) {
	try {
		yield call(api.put, "users", {...payload.data});

		toast.success("Eba! Seu perfil foi atualizado!");

		yield put(updateUserSuccess(payload.data));    
	} catch (error) {
		toast.error("Ops! Tivemos um erro ao tentar atualizar seus dados.");
	}
}

export default all([
	takeLatest("@user/UPDATE_PROFILE_REQUEST", updateProfile),
	takeLatest("@user/UPDATE_AVATAR_REQUEST", updateAvatar),
	takeLatest("@user/UPDATE_USER_REQUEST", updateUser),
]);
