import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import PerfectScrollbar from "react-perfect-scrollbar";
import { 
	Button,
	Card,
	CardContent,
	CardActions,
	CircularProgress,
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableRow,
	TablePagination,
	Tooltip,	
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({	
	root: {
		padding: theme.spacing(2),
		flex: 1		
	},
	content: {
		marginTop: theme.spacing(1),		     
		justifyContent: "center"		
	},	
	inner: {
		minWidth: 800
	},
	tableCell: {    
		display: "flex",
		flexDirection: "row"
	},
	avatar: {
		marginRight: theme.spacing(2)
	},		
	actions: {
		justifyContent: "flex-end"
	},
	button: {
		backgroundColor: theme.palette.primary.main
	},
	row: {
		height: "42px",
		display: "flex",
		alignItems: "center",
		marginTop: theme.spacing(1)
	},
	spacer: {
		flexGrow: 1
	}	
}));

const TableRender = props => {
	const { loading, data, values, columns, buttonOptions, tablePaginationOptions } = props;

	const classes = useStyles();	

	return (
		<div className={classes.content}>      							
			<Card
				className={classes.root}
			>
				<CardContent className={classes.content}>				
					{loading?                        
						<CircularProgress/>:				
						<PerfectScrollbar>
							<div className={classes.inner}>
								<Table>
									<TableHead>
										<TableRow>		
											{columns.map(column => (
												<TableCell key={column}>{column}</TableCell>									
											))}							
											<TableCell></TableCell>							
										</TableRow>
									</TableHead>
									<TableBody>
										{data.map(object => (
											<TableRow
												className={classes.tableRow}
												hover
												key={object.id}										
											>																		
												{values.map((value, index) => (
													<TableCell key={index}>												
														{value(object)}                          
													</TableCell>					  
												))}																						
												<TableCell>
													<div>														
														<Tooltip title={buttonOptions.tooltip}>															
															<Button 
																className={classes.evaluationButton}
																color="primary" 
																variant="contained"
																onClick={() => buttonOptions.onClick(object)}
																disabled={buttonOptions.disabled? buttonOptions.disabled(object): false}
															>
																{typeof buttonOptions.title === "function" ? buttonOptions.title(object) : buttonOptions.title}
															</Button>															
														</Tooltip>			
													</div>								
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</div>
						</PerfectScrollbar>
					}
				</CardContent>
				<CardActions className={classes.actions}>
					<TablePagination
						component="div"
						count={tablePaginationOptions.total}
						onChangePage={tablePaginationOptions.onChangePage}
						page={tablePaginationOptions.page}
						labelDisplayedRows={({from, to, count}) => `${from}-${to} de ${count}`}
						rowsPerPage={tablePaginationOptions.rowsPerPage || 10}
						rowsPerPageOptions={[10]}
					/>
				</CardActions>
			</Card>
		</div>
	);
};

TableRender.propTypes = {
	data: PropTypes.array,
	columns: PropTypes.array,
	values: PropTypes.array,
	loading: PropTypes.bool,
	buttonOptions: PropTypes.shape({
		tooltip: PropTypes.string,
		onClick: PropTypes.func,
		title: PropTypes.any,
		disabled: PropTypes.func
	}),
	tablePaginationOptions: PropTypes.shape({
		total: PropTypes.number,
		onChangePage: PropTypes.func,
		page: PropTypes.number,
		rowsPerPage: PropTypes.number,
	}),
	className: PropTypes.string,
	onChange: PropTypes.func,
	style: PropTypes.object,
	value: PropTypes.any
};

export default TableRender;
