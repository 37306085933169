import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import api from "../../services/api";
import { toast } from "react-toastify";
import { SearchInput, SelectArea, ConfirmRequest, TableRender, DialogRender } from "components";
import {  	
	Link,
	Grid
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3)
	},
	content: {
		marginTop: theme.spacing(1),
		padding: 0
	},	
	inner: {
		minWidth: 800
	},
	nameContainer: {
		display: "flex",
		alignItems: "center"
	},
	avatar: {
		marginRight: theme.spacing(2)
	},
	avatarCheckIcon: {
		backgroundColor: theme.palette.success.main,
		height: 22,
		width: 22,
		marginLeft: 5
	},
	avatarCancelIcon: {
		backgroundColor: theme.palette.error.main,
		height: 22,
		width: 22,
		marginLeft: 5
	},
	checkIcon: {
		height: 14,
		width: 14
	},
	cancelIcon: {
		height: 14,
		width: 14
	},
	actions: {
		justifyContent: "flex-end"
	},
	button: {
		backgroundColor: theme.palette.primary.main
	},
	row: {
		display: "flex",
		alignItems: "center",
	},
	spacer: {
		flexGrow: 1
	},
	importButton: {
		marginRight: theme.spacing(1)
	},
	exportButton: {
		marginRight: theme.spacing(1)
	},
	itemMargin: {
		margin: theme.spacing(.5)
	}
}));

const ProjectRequestEvaluationList = () => {
	const classes = useStyles();

	const [loading, setLoading] = useState(false);
	const [projects, setProjects] = useState([]);
	const [totalProjects, setTotalProjects] = useState(0);	
	const [page, setPage] = useState(0);  
	const [area, setArea] = useState(0);
	const [search, setSearch] = useState("");
	const [opened, setOpen] = useState(false);
	const [resumeOpen, setResumeOpen] = useState(false);
	const [projectSelected, setSelectedProject] = useState({});
	const columns = ["Título", "Área", "Resumo"];
	const values = [
		(data) => data.title,
		(data) => `${data.area.description} - ${data.subarea.description}`,
		(data) => <> <Link href="#" onClick={() => {handleDialog(data);}}variant="h6">Ver resumo</Link></>		
	];
  
	const handlePageChange = (event, page) => {		
		setPage(page);
	};	
  
	const handleDialog = (data) => {
		setSelectedProject(data);
		setResumeOpen(true);
	};

	async function loadProjects(page) {    
		setLoading(true);
		const response = await api.get("projects", {
			params: { page: page + 1, area, search },
		});

		setProjects(response.data.projects);
		setTotalProjects(response.data.total);		
		setLoading(false);
	}
  
	async function handleRequest(value) {
		try {			
			if (!value) {
				setOpen(false);
				return;
			}

			await api.post("/evaluations/requests", {projectId: projectSelected.id });
      
			toast.success("Solicitação cadastrada com sucesso! Em breve alguém irá revisá-la.");      
      
			setProjects(projects.map((project) => {
				if (project.id === projectSelected.id)
					project.requested = true;
				return {
					...project
				};
			}));
			setOpen(false);
		} catch(err){
			setOpen(false);
			toast.error("Ops! Não conseguimos cadastrar sua solicitação.");
		}
	};
  
	useEffect(() => {
		loadProjects(page);
	}, [page]);
  
	useEffect(() => {
		loadProjects(page);
	}, [area]);
  
	useEffect(() => {
		loadProjects(page);
	}, [search]);

	useEffect(() => {		
		loadProjects(0);
	},[]);
  

	return (
		<div className={classes.root}>			
			<Grid container className={classes.row}>
				<Grid item xs={12} sm={12} md={5} className={classes.itemMargin}>
					<SearchInput
						className={classes.searchInput}
						placeholder="Procurar projeto por título"
						value={search}
						onChange={(event) => setSearch(event.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={10} md={4} className={classes.itemMargin}>
					<SelectArea allOption setArea={setArea}></SelectArea>
				</Grid>			
			</Grid>			
			<div className={classes.content}>				
				<ConfirmRequest opened={opened} handleClose={handleRequest} />
				<DialogRender opened={resumeOpen} setOpen={setResumeOpen} dialogTitle={projectSelected.title} title="Resumo do projeto" text={projectSelected.abstract} />																        
				<TableRender
					loading={loading}
					data={projects}
					columns={columns}
					values={values}
					buttonOptions={{            
						tooltip: "Clique para solicitar a avaliação",
						onClick: (project) => {setOpen(true); setSelectedProject(project);},
						disabled: (project) => { return (project.evaluate || project.requested); },
						title: (project) => {
							return (project.requested || project.evaluate) ? "Solicitado" : "Solicitar";
						}
					}}
					tablePaginationOptions={{            
						total: totalProjects,
						onChangePage: (event, page) => handlePageChange(event, page),
						page: page
					}}
				/>																	
			</div>
		</div>
	);
};

export default ProjectRequestEvaluationList;
