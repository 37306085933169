import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useSelector, useDispatch  } from "react-redux";
import { updateUserRequest } from "../../../../store/modules/user/actions";
import {	
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
	Button,
	TextField,	
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
	root: {},	
}));

const PersonalInformation = () => {
	const userState = useSelector((state) => state.user.profile);
	const [nickname, setNickname] = useState(userState.nickname);	
	const dispatch = useDispatch();
  
	const handleChange = event => {
		setNickname(event.target.value);		
	};
  
	const handleClick = async () => {
		dispatch(updateUserRequest({nickname}));		
	};

	return (
		<Card>
			<form>
				<CardHeader					
					title="Informações pessoais"
				/>
				<Divider />
				<CardContent>
					<TextField
						fullWidth
						label="Apelido"
						name="nickname"
						onChange={handleChange}
						type="text"
						value={nickname}
						variant="outlined"
					/>					  
				</CardContent>
				<Divider />
				<CardActions>					
					<Button
						color="primary"
						variant="outlined"						
						onClick={handleClick}
					>
            Atualizar
					</Button>					
				</CardActions>
			</form>
		</Card>
	);
};

export default PersonalInformation;
