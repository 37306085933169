export function updateProfileRequest(data) {
	return {
		type: "@user/UPDATE_PROFILE_REQUEST",
		payload: {
			data,
		},
	};
}

export function updateProfileSuccess(profile) {
	return {
		type: "@user/UPDATE_PROFILE_SUCCESS",
		payload: {
			profile,
		},
	};
}

export function updateProfileFailure() {
	return {
		type: "@user/UPDATE_PROFILE_FAILURE",
	};
}

export function updateAvatarRequest(data) {
	return {
		type: "@user/UPDATE_AVATAR_REQUEST",
		payload: {
			data,
		},
	};
}

export function updateAvatarSuccess(avatar) {
	return {
		type: "@user/UPDATE_AVATAR_SUCCESS",
		payload: {
			avatar,
		},
	};
}

export function updateUserRequest(data) {
	return {
		type: "@user/UPDATE_USER_REQUEST",
		payload: {
			data,
		},
	};
}

export function updateUserSuccess(profile) {
	return {
		type: "@user/UPDATE_USER_SUCCESS",
		payload: {
			profile,
		},
	};
}
