import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Grid, Typography, Avatar } from "@material-ui/core";
import InsertIcon from "@material-ui/icons/InsertChartOutlined";
import PeopleIcon from "@material-ui/icons/People";
import SchoolIcon from "@material-ui/icons/School";
import TimeIcon from "@material-ui/icons/AccessTime";

const useStyles = makeStyles(theme => ({
	root: {
		height: "100%"
	},
	content: {
		alignItems: "center",
		display: "flex"
	},
	title: {
		fontWeight: 700
	},
	avatar: {
		backgroundColor: theme.palette.error.main,
		height: 56,
		width: 56
	},
	icon: {
		height: 32,
		width: 32
	},
	school: {    
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		height: 56,
		width: 56    
	},
	people: {
		backgroundColor: theme.palette.success.main,		
		height: 56,
		width: 56    
	},
	insert: {
		backgroundColor: theme.palette.error.main,		
		height: 56,
		width: 56    
	},
	time: {
		backgroundColor: theme.palette.success.main,		
		height: 56,
		width: 56    
	},
	difference: {
		marginTop: theme.spacing(2),
		display: "flex",
		alignItems: "center"
	},
	differenceIcon: {
		color: theme.palette.error.dark
	},
	differenceValue: {
		color: theme.palette.error.dark,
		marginRight: theme.spacing(1)
	}
}));

const DashboardCard = props => {
	const { className, title, value, icon, ...rest } = props;

	const classes = useStyles();  
  
	let IconJsx;

	switch(icon) {
	case "people":
		IconJsx = PeopleIcon;
		break;
	case "school":
		IconJsx = SchoolIcon;
		break;
	case "insert":
		IconJsx = InsertIcon;
		break;
	case "time":
		IconJsx = TimeIcon;
		break;
	};

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardContent>
				<Grid
					container
					justify="space-between"
				>
					<Grid item>
						<Typography
							className={classes.title}
							color="textSecondary"
							gutterBottom
							variant="body2"
						>
							{title}              
						</Typography>
						<Typography variant="h3">{value}</Typography>
					</Grid>
					<Grid item>                        
						<Avatar className={classes[icon]}>
							<IconJsx className={classes.icon}></IconJsx>							
						</Avatar>
					</Grid>
				</Grid>				
			</CardContent>
		</Card>
	);
};

DashboardCard.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	value: PropTypes.number,
	icon: PropTypes.string
};

export default DashboardCard;
