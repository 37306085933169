import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Typography, Link } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: "auto",
		padding: theme.spacing(4)
	}
}));

const Footer = props => {
	const { className, ...rest } = props;

	const classes = useStyles();

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Typography variant="body1">
        &copy;{" "}
				<Link
					component="a"
					href="http://www.tekoa.ong.br/"
					target="_blank"
				>
          Instituto Tekoá
				</Link>
        . {new Date().getFullYear()}
			</Typography>
			<Typography variant="caption">
        Criado com carinho pelo Instituto Tekoá
			</Typography>
		</div>
	);
};

Footer.propTypes = {
	className: PropTypes.string
};

export default Footer;
