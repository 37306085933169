import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import Youtube from "react-youtube";
import {
	Grid,
	Card,
	CardContent,
	CardActions,
	Divider,
	Link,
	Button,
	TextField,
	CircularProgress,
	Typography,
} from "@material-ui/core";
import api from "../../services/api";
import history from "../../services/history";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
		justifyContent: "center",
	},
	grid: {
		padding: 8,
		textAlign: "center",
		border: "1px solid #eee",
	},
	cardHeader: {
		textAlign: "center",
		padding: 16,
	},
	links: {
		justifyContent: "center",
	},
	link: {
		padding: 10,
	},
	evaluateTitle: {
		textAlign: "center",
		padding: 16,
		flex: 1,
	},
	buttons: {
		display: "block",
		textAlign: "center",
	}
}));

const ProjectReviewPage = (props) => {
	const classes = useStyles();
	const [project, setProject] = useState();
	const [questions, setQuestions] = useState([]);
	const [questionsAnswered, setQuestionsAnswered] = useState([]);
	const projectId = props.match.params.id;
	const [loading, setLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);

	useEffect(() => {
		async function loadProject() {
			const response = await api.get(`/reviews/${projectId}`);

			setProject(response.data.project);
			setQuestions(response.data.questions);
			setQuestionsAnswered(
				response.data.questions.map((question) => ({
					id: question.id,
					type: question.type,
					text: question.text,
				}))
			);
			setLoading(false);
		}
		loadProject();
	}, []);

	const handleChange = (event, id) => {
		setQuestionsAnswered(
			questionsAnswered.map((question) => {
				if (question.id === id) {
					question.text = event.target.value;
				}

				return {
					...question,
				};
			})
		);
	};

	const handleSave = async () => {
		setIsSaving(true);
		try {
			await api.post(`/reviews/${projectId}/notes`, {
				notes: questionsAnswered,
			});

			toast.success("Salvo com sucesso!");
			setIsSaving(false);
			history.goBack();
		} catch (err) {
			toast.error("Ops! Tivemos um erro ao tentar salvar suas notas");
			setIsSaving(false);
		}
	};

	const windowWidth = window.innerWidth;
	let videoWidth = 640;
	let videoHeight = 390;

	if (windowWidth < 500)
	{
		videoWidth = windowWidth * 0.70;
		videoHeight = videoWidth * 0.61;
	}
	else if (windowWidth < 700)
	{
		videoWidth = windowWidth * 0.75;
		videoHeight = videoWidth * 0.61;
	}

	const opts = {
		height: videoHeight.toString(),
		width: videoWidth.toString(),
		playerVars: {
			autoplay: 0,
		},
	};

	return (
		<div className={classes.root}>
			<Grid container spacing={4}>
				<Grid item lg={12} md={12} xl={12} xs={12}>
					<Card className={classes.root}>
						{loading ? (
							<CircularProgress />
						) : (
							<div>
								<div className={classes.cardHeader}>
									<Typography variant="h3">{project.title}</Typography>
									<Typography variant="h6">
										{`${project.area.description} - ${project.subarea.description}`}
									</Typography>
									{project && project.event && (
										<Typography variant="h6">
											{project.event.name} -{" "}
											{project.phase === 1
												? "Projeto em andamento"
												: "Projeto em concepção"}
										</Typography>
									)}
								</div>

								<Grid item md={12} xs={12} className={classes.grid}>
									<Typography variant="h4">Resumo do projeto</Typography>
									<Typography variant="h6">{project.abstract}</Typography>
								</Grid>

								<Grid item md={12} xs={12} className={classes.grid}>
									<Typography variant="h4">Links do projeto</Typography>
									<Grid className={classes.links} container>
										<Link
											color="secondary"
											href={project.responsibility_link}
											target="_blank"
											variant="h6"
											className={classes.link}
										>
                      Formulário de responsabilidade
										</Link>
										{project && project.expanded_abstract_link && (
											<Link
												color="secondary"
												href={project.expanded_abstract_link}
												target="_blank"
												variant="h6"
												className={classes.link}
											>
                        Resumo expandido
											</Link>
										)}
										<Link
											color="secondary"
											href={project.research_project_link}
											target="_blank"
											variant="h6"
											className={classes.link}
										>
                      Projeto de pesquisa
										</Link>
										{project && project.continuity_link && (
											<Link
												href={project.continuity_link}
												target="_blank"
												variant="h6"
												color="secondary"
												className={classes.link}
											>
                        Formulário de continuidade
											</Link>
										)}
									</Grid>
								</Grid>

								{(project.youtube_link && project.youtube_link !== "") && <Grid className={classes.grid} md={12} xs={12} item>
									<Typography variant="h4">Vídeo</Typography>
									<Youtube videoId={project.youtube_link} opts={opts}></Youtube>
								</Grid>}

								<Divider />

								<CardContent>
									<Grid container spacing={3}>
										<Typography variant="h3" className={classes.evaluateTitle}>
                      Revisão científica
										</Typography>

										{questions.map((question) => (
											<Grid item key={question.id} md={12} xs={12}>
												<Typography variant="h5">
													{question.description}
												</Typography>
												<Typography variant="subtitle2">
													{question.auxiliary_text}
												</Typography>
												<TextField
													fullWidth
													multiline
													margin="dense"
													name="note"
													onChange={(event) => {
														handleChange(event, question.id);
													}}
													rows={4}
													value={
														questionsAnswered.some((x) => x.id === question.id)
															? questionsAnswered.find(
																(x) => x.id === question.id
															).text
															: ""
													}
													variant="outlined"
												/>
											</Grid>
										))}
									</Grid>
								</CardContent>
								<Divider />
								<CardActions className={classes.buttons}>
									<Button
										color="primary"
										variant="contained"
										disabled={isSaving}
										onClick={handleSave}
									>
                    Salvar e atualizar status
									</Button>
								</CardActions>
							</div>
						)}
					</Card>
				</Grid>
			</Grid>
		</div>
	);
};

export default ProjectReviewPage;
