import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import { 
	AppBar,
	Tabs,
	Tab,
	Box,
	Grid,
	Card,		
	CircularProgress,	
	Typography,
} from "@material-ui/core";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(4),
		justifyContent: "center",
		display: "flex"
	},
	grid: {
		padding: 16,
		textAlign: "center",
		border: "1px solid #eee"
	},
	cardHeader: {
		textAlign: "center",
		padding: 16
	},
	links: {
		justifyContent: "center"
	},
	link: {
		padding: 10,		
	},	
	evaluateTitle: {
		textAlign: "center",
		padding: 16,
		flex: 1
	}
}));

const ProjectResultView = (props) => {
	const classes = useStyles();
	const [project, setProject] = useState({});			
	const projectId = props.match.params.id;
	const [loading, setLoading] = useState(true);	
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
    
	useEffect(() => {		
		async function loadProject() {
			const response = await api.get(`/projects/${projectId}/evaluations`);      

			setProject(response.data);      			
      
			setLoading(false);						
		};		     	
    		
		loadProject();						
	}, []);			
  
	return (
		<div className={classes.root}>
			<Grid
				container
				spacing={4}
			>			
				<Grid
					item					
					xs={12}
				>																				
					<Card
						className={classes.root}
					>            
						{loading?                        
							<CircularProgress/>:											
							<Grid container direction="column" spacing={3}>												
								<Grid item container direction="row">
									<Grid container item sm={8} xs={12} direction="column">									
										<Typography variant="h3">{project.title}</Typography>     
										<Typography variant="h6">{`Código: ${projectId}`}</Typography>     
										<Typography variant="h6">
											{project.area.description} - {project.subarea.description}
										</Typography>                    
										{project && project.event && 
                      <Typography variant="h6">
                      	{project.event.name} - {project.phase === 1? "Projeto em andamento": "Projeto em concepção"}
                      </Typography>}									
									</Grid>																																															
								</Grid>
								<Grid item direction="column" xs={"auto"}>
									<Box pb={2}>
										<Typography align="justify" variant='subtitle2'>
                      O parecer do projeto é um resumo das avaliações realizadas pelos nossos avaliadores convidados.
                      Estes profissionais são selecionados pelo Comitê Científico da feira em um processo de verificação de currículo e experiência.
                      Não obstante, este ano os membros do Comitê Científico realizaram a revisão de todos os projetos submetidos, apontando possíveis melhorias e correções.
                      Todo este processo tem como intuito contribuir de forma relevante o trabalho dos pesquisadores.    
											<br></br>
                      Abaixo você consegue acessar as notas e comentários.
										</Typography>
									</Box>
									<AppBar position="static">
										<Tabs value={value}  onChange={handleChange} aria-label="simple tabs example">
											{project.Evaluations.map((evaluation, index) => (
												<Tab label={`Avaliador ${index + 1}`} key={evaluation.id}/>
											))}         
											<Tab label='Comitê Científico' />                             
										</Tabs>
									</AppBar>                  
									{project.Evaluations.map((evaluation, index) => (                    
										value === index && (                      
											<Box visibility={value === index? "visible": "hidden"} key={evaluation.id} py={2}>												
												{evaluation.EvaluationsNotes.map((evaluationNote) => (
													<Box border={1} borderColor="grey.200" key={evaluationNote.id} p={1}>
														<Typography variant="h5">{evaluationNote.question.description}</Typography>
														<Typography variant="subtitle2">
															{evaluationNote.question.auxiliary_text}{evaluationNote.question.type === 1 ? ` - Peso: ${evaluationNote.question.weight}`: ""}</Typography>                                                                                    
														<Typography>
															{evaluationNote.question.type === 1 ? `Nota: ${evaluationNote.note}`: `${evaluationNote.text}`}
														</Typography>                        												                                               
													</Box>
												))}                        
											</Box>										                    
										)                    
									))}									
									{value === project.Evaluations.length && (
										<Box visibility={project.Evaluations && value === project.Evaluations.length ? "visible": "hidden"} py={2}>												
											{project.ProjectReview && project.ProjectReview.ProjectReviewNotes.map((projectNote) => (
												<Box border={1} borderColor="grey.200" key={projectNote.id} p={1}>
													<Typography variant="h5">{projectNote.question.description}</Typography>													
													<Typography>
														{projectNote.text}														
													</Typography>                        												                                               
												</Box>
											))}                        
										</Box>										                    
									)}									
								</Grid>
							</Grid>
						}
					</Card>
					
				</Grid>
			</Grid>
		</div>
	);
};

export default ProjectResultView;
