import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import api from "../../services/api";
import {  
	Button, Menu, MenuItem	
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { selectProject } from "../../store/modules/project/actions";
import { SearchInput, SelectArea, TableRender, } from "components";
import { ProjectEvaluationDetails } from "./components";
import Grid from "@material-ui/core/Grid";
import { saveAs } from "file-saver";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2)
	},	
	inner: {
		minWidth: 800
	},
	button: {
		backgroundColor: theme.palette.primary.main
	},
	exportData: {
		marginLeft: "auto"
	},
	row: {
		display: "flex",
		alignItems: "center",
	},
	itemMargin: {
		margin: theme.spacing(.5)
	},
}));

const ProjectEvaluationList = () => {
	const classes = useStyles();

	const [projects, setProjects] = useState([]);
	const [totalProjects, setTotalProjects] = useState(0);	
	const [page, setPage] = useState(0);  
	const [area, setArea] = useState(0);
	const [search, setSearch] = useState("");
	const [dialogOpen, setDialogOpen] = useState(false);
	const [selectedProject, setSelectedProject] = useState(null);
	const [loading, setLoading] = useState(false);
	const [anchorEl, setArcholEl] = useState(null);
	const columns = ["Título", "Área", "Avaliações finalizadas"];
	const values = [
		(project) => {
			return project.title;
		},
		(project) => {
			return `${project.area.description} - ${project.subarea.description}`;													
		},
		(project) => {
			return `${project.Evaluations.filter(x => x.finished === true).length}/${project.Evaluations.length}`;
		}
	];  

	const dispatch = useDispatch();
  
	const handlePageChange = (event, page) => {		
		setPage(page);
	};	

	async function loadProjects() {    
		setLoading(true);
		const response = await api.get("projects/informations", {
			params: { page: page + 1, area, search },
		});  		

		setProjects(response.data.projects);
		setTotalProjects(response.data.total);
		setLoading(false);
	}
  
	function handleClick(project) {
		dispatch(selectProject({
			id: project.id,
			title: project.title,
			area: project.area.description,
			area_id: project.area.id,
			subarea: project.subarea.description,
			subarea_id: project.subarea.id,
			phase: project.phase,
			responsibility_link: project.responsibility_link,
			youtube_link: project.youtube_link,
			research_project_link: project.research_project_link,
			expanded_abstract_link: project.expanded_abstract_link,
			evaluation_video_link: project.evaluation_video_link,
			poster_link: project.poster_link,
			abstract: project.abstract,
			continuity_link: project.continuity_link
		}));
		setSelectedProject(project.id);
		setDialogOpen(true);
	}

	const handleExportClick = (event) => {
		setArcholEl(event.currentTarget);
	};	

	const handleExportClose = () => {
		setArcholEl(null);
	};

	const exportProjects = async (type) => {
		const response = await api.get("/projects/evaluations/download",{ params: { type }, responseType: "blob"});

		const blobType = type === "xlsx" ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
			"application/json";

		const file = new Blob(
			[response.data],
			{type: blobType}
		);					

		saveAs(file, `avaliacoes.${type}`);
	};
  
	useEffect(() => {
		loadProjects();
	}, [page]);
  
	useEffect(() => {
		loadProjects();
	}, [area]);
  
	useEffect(() => {
		loadProjects();
	}, [search]);

	useEffect(() => {		
		loadProjects(0);
	},[]);

	return (
		<div className={classes.root}>			
			<Grid container className={classes.row}>
				<Grid item xs={12} md={5} lg={4} className={classes.itemMargin}>
					<SearchInput
						placeholder="Procurar projeto por título"
						value={search}
						onChange={(event) => setSearch(event.target.value)}
					/>
				</Grid>		
				<Grid item xs={12} md={5} lg={4} className={classes.itemMargin}>
					<SelectArea allOption setArea={setArea}></SelectArea>
				</Grid>		
				<Grid item xs={12} md={5} lg={3} className={classes.itemMargin}>
					<Button
						aria-controls="export-menu" 
						aria-haspopup="true"
						className={classes.exportData}
						onClick={handleExportClick}
						variant="contained"
						color="primary"
					>
					Exportar dados
					</Button>
					<Menu
						id="export-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleExportClose}
					>
						<MenuItem onClick={() => exportProjects("xlsx")}>Exportar como XLSX</MenuItem>
						<MenuItem onClick={() => exportProjects("json")}>Exportar como JSON</MenuItem>				
					</Menu>
				</Grid>
			</Grid>			
			<ProjectEvaluationDetails opened={dialogOpen} setOpen={setDialogOpen} projectId={selectedProject} updateList={loadProjects} />			
			<TableRender
				loading={loading}
				data={projects}
				columns={columns}
				values={values}
				buttonOptions={{            
					tooltip: "Clique para listar e atribuir avaliações",
					onClick: (project) => handleClick(project),
					title: "Avaliações"
				}}
				tablePaginationOptions={{            
					total: totalProjects,
					onChangePage: (event, page) => handlePageChange(event, page),
					page: page
				}}
			/>							
		</div>
	);
};

export default ProjectEvaluationList;
