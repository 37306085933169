import React from "react";
import { Switch } from "react-router-dom";

import Route from "./Route";
import {
	Dashboard as DashboardView,		
	CertificateList as CertificateListView,
	ProfessorCertificateList as ProfessorCertificateListView,
	EvaluationList as EvaluationListView,
	ProjectEvaluate as ProjectEvaluateView,
	ProjectRequestEvaluationList as ProjectRequestEvaluationListView,
	ProjectReviewList as ProjectReviewListView,
	ProjectReviewPage as ProjectReviewPageView,
	RequestList as RequestListView,
	ProjectEvaluationList as ProjectEvaluationListView,
	EvaluatorsList as EvaluatorsListView,
	EvaluatorsPendingList as EvaluatorsPendingListView,
	Settings as SettingsView,
	SignUp as SignUpView,
	SignIn as SignInView,
	RecoveryPassword as RecoveryPasswordView,
	ResetPassword as ResetPasswordView,
	UserProjectList as UserProjectListView,
	EditProject as EditProjectView,
	FinalStepProject as FinalStepProjectView,
	ProjectResultList as ProjectResultListView,		
	ProjectResultView
} from "views";

export default function Routes() {
	return (
		<Switch>
			<Route path="/" exact component={SignInView} />
			<Route path="/sign-up" component={SignUpView} />
			<Route path="/recovery-password" component={RecoveryPasswordView} />
			<Route path="/reset-password" component={ResetPasswordView} />
			<Route path="/dashboard" component={DashboardView} isPrivate />            			
			<Route path="/evaluations" exact component={EvaluationListView} isPrivate />   
			<Route path="/projects/list" component={ProjectRequestEvaluationListView} isPrivate />   
			<Route path="/projects/:id/evaluate" component={ProjectEvaluateView} isPrivate />            
			<Route path="/requests" component={RequestListView} isPrivate />            
			<Route path="/projects/informations" component={ProjectEvaluationListView} isPrivate />            
			<Route path="/projects/reviews" exact component={ProjectReviewListView} isPrivate />
			<Route path="/projects/reviews/:id" component={ProjectReviewPageView} isPrivate />
			<Route path="/projects/evaluations" exact component={ProjectResultListView} isPrivate />
			<Route path="/projects/:id/evaluations" exact component={ProjectResultView} isPrivate />
			<Route path="/projects/:id/final" exact component={FinalStepProjectView} isPrivate />
			<Route path="/users/projects" component={UserProjectListView} isPrivate />
			<Route path="/projects/:id" component={EditProjectView} isPrivate />            
			<Route path="/users/pending" component={EvaluatorsPendingListView} isPrivate />            
			<Route path="/evaluators" component={EvaluatorsListView} isPrivate />            
			<Route path="/certificates/projects" exact component={ProfessorCertificateListView} isPrivate />            
			<Route path="/certificates" component={CertificateListView} isPrivate />            			
			<Route path="/settings" component={SettingsView} isPrivate />            
		</Switch>
	);
};
