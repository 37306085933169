import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.background.default,
		height: "100%"
	},
	grid: {
		height: "100%"
	},
	buttonStep:{
		display:"flex",
		justifyContent: "space-between"
	},
	quoteContainer: {
		[theme.breakpoints.down("md")]: {
			display: "none"
		}
	},
	quote: {
		backgroundColor: theme.palette.neutral,
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundImage: "url(/images/amarelo.png)",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center"
	},
	quoteInner: {
		textAlign: "center",
		flexBasis: "600px"
	},
	quoteText: {
		color: theme.palette.white,
		fontWeight: 300
	},
	name: {
		marginTop: theme.spacing(3),
		color: theme.palette.white
	},
	bio: {
		color: theme.palette.white
	},
	contentContainer: {},
	content: {
		marginTop:"20px",
		height: "100%",
		display: "flex",
		flexDirection: "column"
	},
	contentHeader: {
		display: "flex",
		alignItems: "center",
		paddingTop: theme.spacing(5),
		paddingBototm: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2)
	},
	logoImage: {
		marginLeft: theme.spacing(4)
	},
	contentBody: {
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		[theme.breakpoints.down("md")]: {
			justifyContent: "center"
		}
	},
	form: {
		paddingLeft: 100,
		paddingRight: 100,
		paddingBottom: 125,
		flexBasis: 700,
		[theme.breakpoints.down("sm")]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2)
		}
	},
	checkbox: {
		display: "flex",
		flexDirection:"row",
	},
	title: {
		marginTop: theme.spacing(3)
	},
	subtitle: {
		fontFamily:"Roboto",
		fontSize:"20px",
		marginBottom:"10px"
	},
	textField: {
		marginTop: theme.spacing(2)
	},
	policy: {
		marginTop: theme.spacing(1),
		display: "flex",
		alignItems: "center"
	},
	policyCheckbox: {
		marginLeft: "-14px"
	},
	signUpButton: {
		width:"100%",
		margin: theme.spacing(2, 0),
		backgroundColor: theme.palette.primary.main
	},
	buttonStepStyle: {
		margin: theme.spacing(2, 0)
	}
}));

export default useStyles;
