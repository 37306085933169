import axios from "axios";
import { signOut } from "store/modules/auth/actions";
import { store } from "../store/index";

// eslint-disable-next-line no-undef
const baseURL = process.env.REACT_APP_BASE_API_URL || "http://localhost:3333"; 

const api = axios.create({
	baseURL
});

api.interceptors.response.use((response) => {
	return response;
}, (error) => {
	if (error.response && error.response.status === 401) {				
		store.dispatch(signOut());
	} else {
		throw error;
	}
});

export default api;
