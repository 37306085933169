import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import FilterListIcon from "@material-ui/icons/FilterList";
import {	
	Select,
	MenuItem,
	Paper,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		borderRadius: "4px",
		alignItems: "center",
		padding: theme.spacing(1),
		display: "flex",
		flexBasis: 220
	},
	icon: {
		marginRight: theme.spacing(1),
		color: theme.palette.text.secondary
	},
	input: {
		flexGrow: 1,		
		marginLeft: "10px"
	},		
}));

const SelectTotalEvaluations = props => {
	const { setTotal } = props;
	const [totalSelected, setTotalSelected] = useState(0);
	const [totalOptions,] = useState([ { id: 1, label: ">= 3 avaliações"}, {id: 2, label: "< 3 avaliações"}]);	
  
	function handleChange(event) {
		setTotalSelected(event.target.value);
		setTotal(event.target.value);  		
	}

	const classes = useStyles();

	return (
	
		<Paper className={classes.root}>
			<FilterListIcon></FilterListIcon>
			<Select
				className={classes.input}				
				fullWidth
				value={totalSelected}
				onChange={handleChange}
			>
				<MenuItem value={0}>
					<em>Todos</em>
				</MenuItem>	
				{totalOptions.map((status) => (
					<MenuItem key={status.id} value={status.id}>{status.label}</MenuItem>
				))}						
			</Select>
		</Paper>
	  
	);
};

SelectTotalEvaluations.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
	style: PropTypes.object,
	setTotal: PropTypes.func	
};

export default SelectTotalEvaluations;
