import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { signInRequest } from "../../store/modules/auth/actions";

import { Link as RouterLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import validate from "validate.js";
import { makeStyles } from "@material-ui/styles";
import {
	Grid,
	Button,
	TextField,
	Link,
	Typography
} from "@material-ui/core";

const schema = {
	email: {
		presence: { allowEmpty: false, message: "^E-mail é requerido" },
		email: { message: "^Não parece ser um e-mail válido" },
		length: {
			maximum: 64
		}
	},
	password: {
		presence: { allowEmpty: false, message: "^Senha é requerida" },    
		length: {
			maximum: 128
		}
	}
};

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.background.default,
		height: "100%"
	},
	grid: {
		height: "100%"
	},
	quoteContainer: {
		[theme.breakpoints.down("md")]: {
			display: "none"
		}
	},  
	image: {
		width: 200,
		height: 180, 
		backgroundImage: "url(/images/logos/tekoa-logo.svg)",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		display: "block",
		marginLeft: "auto",
		marginRight: "auto"    
	},
	quote: {
		backgroundColor: theme.palette.neutral,
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundImage: "url(/images/amarelo.png)",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center"
	},
	quoteInner: {
		textAlign: "center",
		flexBasis: "600px"
	},
	quoteText: {
		color: theme.palette.white,
		fontWeight: 300
	},
	name: {
		marginTop: theme.spacing(3),
		color: theme.palette.white
	},
	bio: {
		color: theme.palette.white
	},
	contentContainer: {},
	content: {
		height: "100%",
		display: "flex",
		flexDirection: "column"
	},
	contentHeader: {
		display: "flex",
		alignItems: "center",
		paddingTop: theme.spacing(5),
		paddingBototm: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2)
	},
	logoImage: {
		marginLeft: theme.spacing(4)
	},
	contentBody: {
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		[theme.breakpoints.down("md")]: {
			justifyContent: "center"
		}
	},
	form: {
		paddingLeft: 100,
		paddingRight: 100,
		paddingBottom: 125,
		flexBasis: 700,
		[theme.breakpoints.down("sm")]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2)
		}
	},
	title: {
		marginTop: theme.spacing(3)
	},
	socialButtons: {
		marginTop: theme.spacing(3)
	},
	socialIcon: {
		marginRight: theme.spacing(1)
	},
	sugestion: {
		marginTop: theme.spacing(2)
	},
	textField: {
		marginTop: theme.spacing(2)
	},
	signInButton: {
		margin: theme.spacing(2, 0),
		backgroundColor: theme.palette.primary.main,
	},
	createAccount: {
		color: theme.palette.primary.main
	}
}));

const SignIn = props => {
	const { history } = props;

	const classes = useStyles();
	const dispatch = useDispatch();

	const [formState, setFormState] = useState({
		isValid: false,
		values: {},
		touched: {},
		errors: {}
	});

	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState(formState => ({
			...formState,
			isValid: errors ? false : true,
			errors: errors || {}
		}));
	}, [formState.values]);	

	const handleChange = event => {
		event.persist();

		setFormState(formState => ({
			...formState,
			values: {
				...formState.values,
				[event.target.name]:
          event.target.type === "checkbox"
          	? event.target.checked
          	: event.target.value
			},
			touched: {
				...formState.touched,
				[event.target.name]: true
			}
		}));
	};

	const handleSignIn = event => {
		event.preventDefault();
    
		const { email, password } = formState.values; 
    
		dispatch(signInRequest(email, password));		
	};

	const hasError = field =>
		formState.touched[field] && formState.errors[field] ? true : false;

	return (
		<div className={classes.root}>
			<Grid
				className={classes.grid}
				container
			>
				<Grid
					className={classes.quoteContainer}
					item
					lg={5}
				>
					<div className={classes.quote}>
						<div className={classes.quoteInner}>
							<Typography
								className={classes.quoteText}
								variant="h1"
							>
                Uma janela para a revolução do ensino básico brasileiro
							</Typography>							
						</div>
					</div>
				</Grid>
				<Grid
					className={classes.content}
					item
					lg={7}
					xs={12}
				>
					<div className={classes.content}>						
						<div className={classes.contentBody}>
							<form
								className={classes.form}
								onSubmit={handleSignIn}
							>
								<div className={classes.image}></div>								
								<Typography
									className={classes.title}
									variant="h2"
								>
                  Entrar
								</Typography>															
								<TextField
									className={classes.textField}
									error={hasError("email")}
									fullWidth
									helperText={
										hasError("email") ? formState.errors.email[0] : null
									}
									label="E-mail"
									name="email"
									onChange={handleChange}
									type="text"
									value={formState.values.email || ""}
									variant="outlined"
								/>
								<TextField
									className={classes.textField}
									error={hasError("password")}
									fullWidth
									helperText={
										hasError("password") ? formState.errors.password[0] : null
									}
									label="Senha"
									name="password"
									onChange={handleChange}
									type="password"
									value={formState.values.password || ""}
									variant="outlined"
								/>
								<Button
									className={classes.signInButton}
									color="primary"
									disabled={!formState.isValid}
									fullWidth
									size="large"
									type="submit"
									variant="contained"
								>
                  Entrar
								</Button>
								<Typography
									color="textSecondary"
									variant="body1"
								>
								
									<Link
										component={RouterLink}
										to="/recovery-password"
										variant="h6"
										className={classes.createAccount}
									>
                    Esqueceu a senha?
									</Link>

									<br/>
									<br/>
									 
                  Não tem uma conta?{" "}
									<Link
										component={RouterLink}
										to="/sign-up"
										variant="h6"
										className={classes.createAccount}
									>
                    Cadastrar-se como avaliador
									</Link> 
									
								</Typography>
							</form>
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

SignIn.propTypes = {
	history: PropTypes.object
};

export default withRouter(SignIn);
