import { takeLatest, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";
import { signInSucess, signFailure } from "./actions";
import history from "../../../services/history";
import api from "../../../services/api";

export function* signIn({ payload }) {
	try {
		const { email, password } = payload;

		const response = yield call(api.post, "sessions", {
			email,
			password,
		});
  
		const { token, user } = response.data;

		if (!user) {
			toast.error("Usuário não existe");
			return;
		}

		api.defaults.headers.Authorization = `Bearer ${token}`;

		yield put(signInSucess(token, user));

		history.push("/dashboard");
	} catch (error) {   
		yield put(signFailure());		
     
		if (error.response && error.response.data.inactive) {
			return toast.error("Seu cadastro está pendente de aprovação.");
		}
    
		if (error.response && error.response.data.isStudent) {
			return toast.error("Aluno, entre em contato com o orientador, ele será responsável pela edição do projeto.");
		}
    
		toast.error("Falha na autenticação, verifique seus dados");
	}
}

export function* signUp({ payload }) {
	try {
		const { name, email, password } = payload;
		yield call(api.post, "users", {
			name,
			email,
			password,
			provider: true,
		});

		history.push("/");
	} catch (error) {
		toast.error("Falha no cadastro, verifique seus dados.");
		yield put(signFailure());
	}
}

export function setToken({ payload }) {
	if (!payload) return;

	const { token } = payload.auth;

	if (token) {
		api.defaults.headers.Authorization = `Bearer ${token}`;
	}
}

export function signOut() {
	history.push("/");
}

export default all([
	takeLatest("@auth/SIGN_IN_REQUEST", signIn),
	takeLatest("@auth/SIGN_UP_REQUEST", signUp),
	takeLatest("persist/REHYDRATE", setToken),
	takeLatest("@auth/SIGN_OUT", signOut),
]);
