import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import PeopleIcon from "@material-ui/icons/People";
import CreateIcon from "@material-ui/icons/Create";
import SettingsIcon from "@material-ui/icons/Settings";
import ListIcon from "@material-ui/icons/List";
import EventNoteIcon from "@material-ui/icons/EventNote";
import ScienceIcon from "@material-ui/icons/School";
import MultilineChartIcon from "@material-ui/icons/MultilineChart";

import { Profile, SidebarNav } from "./components";
import { USER_TYPE } from "enums/user-type";

const useStyles = makeStyles(theme => ({
	drawer: {
		width: 240,
		[theme.breakpoints.up("lg")]: {
			marginTop: 64,
			height: "calc(100% - 64px)"
		}
	},
	root: {
		backgroundColor: theme.palette.white,
		display: "flex",
		flexDirection: "column",
		height: "100%",
		padding: theme.spacing(2)
	},
	divider: {
		margin: theme.spacing(2, 0)
	},
	nav: {
		marginBottom: theme.spacing(2)
	}
}));

const Sidebar = props => {
	const { open, variant, onClose, className, ...rest } = props;

	const classes = useStyles();

	const adminPages = [
		{
			title: "Solicitações",
			href: "/requests",
			icon: <CheckBoxIcon />,
			type: USER_TYPE.ADMIN,
			description: "Solicitações de avaliação"
		},
		{
			title: "Avaliadores",
			href: "/evaluators",
			icon: <PeopleIcon />,
			type: USER_TYPE.ADMIN,
			description: "Lista de avaliadores"
		},
		{
			title: "Projetos",
			href: "/projects/informations",
			type: USER_TYPE.ADMIN,
			icon: <ScienceIcon />,
			description: "Lista de projetos"
		},
		{
			title: "Novos usuários",
			href: "/users/pending",
			icon: <ListIcon />,
			type: USER_TYPE.ADMIN,
			description: "Lista de novos usuários"
		},
		{
			title: "Revisões",
			href: "/projects/reviews",
			type: USER_TYPE.ADMIN,
			icon: <EventNoteIcon />,
			description: "Revisão de projetos"
		},		
	];
  
	const consultantPages = [
		{
			title: "Avaliações",
			href: "/evaluations",
			icon: <CreateIcon />,
			type: USER_TYPE.EVALUATOR,
			description: "Lista de projetos a serem avaliados"
		},
		{
			title: "Projetos",
			href: "/projects/list",
			icon: <ScienceIcon />,
			type: USER_TYPE.EVALUATOR,
			description: "Lista de projetos"
		},   
		{
			title: "Certificados",
			href: "/certificates",
			icon: <MultilineChartIcon />,
			type: USER_TYPE.EVALUATOR,
			description: "Lista de certificados"
		},    
	];

	const professorPages = [
		{
			title: "Resultados",
			href: "/projects/evaluations",
			type: USER_TYPE.ADVISOR,
			icon: <MultilineChartIcon />,
			description: "Resultados das avaliações"
		},
	];

	const commonPages = [		
		{
			title: "Configurações",
			href: "/settings",
			icon: <SettingsIcon />,
			type: 0,
			description: ""
		}
	];  

	const pages = [			    
		...adminPages,
		...consultantPages,
		...professorPages,
		...commonPages,			
	];

	return (
		<Drawer
			anchor="left"
			classes={{ paper: classes.drawer }}
			onClose={onClose}
			open={open}
			variant={variant}
		>
			<div
				{...rest}
				className={clsx(classes.root, className)}
			>
				<Profile />
				<Divider className={classes.divider} />
				<SidebarNav
					className={classes.nav}
					pages={pages}
				/>				
			</div>
		</Drawer>
	);
};

Sidebar.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	open: PropTypes.bool.isRequired,
	variant: PropTypes.string.isRequired
};

export default Sidebar;
