import React, { useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { useSelector, useDispatch  } from "react-redux";
import { updateAvatarRequest } from "../../../../store/modules/user/actions";
import {
	Card,
	CardActions,
	CardContent,
	CircularProgress,
	Avatar,
	Typography,
	Divider,
	Button
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {},
	details: {
		display: "flex"
	},
	avatar: {
		marginLeft: "auto",
		height: 110,
		width: 110,
		flexShrink: 0,
		flexGrow: 0
	},
	progress: {
		marginTop: theme.spacing(2)
	},
	uploadButton: {
		marginRight: theme.spacing(2)
	}
}));

const AccountProfile = props => {
	const { className, ...rest } = props;
	const userState = useSelector((state) => state.user.profile);	
	const loading = useSelector((state) => state.user.loading);	
	const inputFile = useRef(null);	
	const dispatch = useDispatch();

	const classes = useStyles();	
  
	const handleFile = async (e) => {		
		e.preventDefault();

		const data = new FormData();
		data.append("file", e.target.files[0]);      			
    
		dispatch(updateAvatarRequest(data));		    					
	};
  
	const handleClick = () => {
		inputFile.current.click();
	};

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardContent>
				<div className={classes.details}>
					<div>
						<Typography
							gutterBottom
							variant="h2"
						>
							{userState.name}
						</Typography>
						{userState.nickname && userState.nickname.length > 0 && 
              <Typography
              	className={classes.locationText}
              	color="textSecondary"
              	variant="body1"
              >
              	{userState.nickname}              
              </Typography>						
						}						
						<Typography
							className={classes.locationText}
							color="textSecondary"
							variant="body1"
						>
							{userState.email}              
						</Typography>						
					</div>
					<Avatar
						className={classes.avatar}
						src={`${userState.avatar}?${new Date().getTime()}`}
					/>
				</div>				
			</CardContent>
			<Divider />
			<CardActions>
				{loading ? 
					<CircularProgress size={20}/>:
					<div>
						<input accept="image/*" ref={inputFile} onChange={handleFile} type="file" style={{ display: "none" }}/>
						<Button
							onClick={handleClick}
							className={classes.uploadButton}
							color="primary"
							variant="text">
              Mudar foto
						</Button>            
					</div>					
				}			
			</CardActions>
		</Card>
	);
};

AccountProfile.propTypes = {
	className: PropTypes.string
};

export default AccountProfile;
