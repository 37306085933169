import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import Youtube from "react-youtube";
import { Grid,Card,
	CardContent,
	CardActions,
	Divider,	
	Link,
	Button,
	TextField,
	CircularProgress,
	Typography,
	Radio,
} from "@material-ui/core";
import { InterestConflictDialog } from "../../components";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		justifyContent: "center",
	},
	grid: {
		padding: 8,
		textAlign: "center",
		border: "1px solid #eee"
	},
	cardHeader: {
		textAlign: "center",
		padding: 16
	},
	links: {
		justifyContent: "center"
	},
	link: {
		padding: 10,		
	},	
	evaluateTitle: {
		textAlign: "center",
		padding: 16,
		flex: 1
	},
	buttons: {
		display: "block",
		textAlign: "center",
	}
}));

const ProjectEvaluate = (props) => {
	const classes = useStyles();
	const [evaluation, setEvaluation] = useState({project: {}});
	const [questions, setQuestions] = useState([]);
	const [questionsAnswered, setQuestionsAnswered] = useState([]);  
	const [dialogOpen, setDialogOpen] = useState(false);
	const evaluationId = props.match.params.id;
	const [loading, setLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
  
	const links = [
		{ field: "responsibility_link", label: "Formulário de responsabilidade"},
		{ field: "research_project_link", label: "Projeto de pesquisa"},
		{ field: "expanded_abstract_link", label: "Resumo expandido"},
		{ field: "continuity_link", label: "Projeto de continuidade"},    
		{ field: "poster_link", label: "Banner"},
	];
    
	useEffect(() => {		

		async function loadEvaluation() {
			const response = await api.get(`/evaluations/${evaluationId}`);      

			setEvaluation(response.data);      
      
			setLoading(false);
			if (response.data.has_interest_conflict === null)
				setDialogOpen(true);
			
		}      

		async function loadQuestions(){
			const response = await api.get(`/evaluations/${evaluationId}/questions`);
			setQuestions(response.data);
			setQuestionsAnswered(response.data.map((question) => ({                
				id: question.id,
				evaluation_id: question.evaluation_id,
				type: question.type,
				note: question.note? question.note: 0,
				text: question.text? question.text: ""
			})));            
		}
		loadQuestions();
		loadEvaluation();				
	}, []);

	const isChecked = (questionId, option) => {
		var note = "0";
		const hasAnswer = questionsAnswered.some(x => x.id === questionId);

		if (hasAnswer) {
			note = questionsAnswered.find(x => x.id === questionId).note;
		}

		if (note == option) {
			return true;
		} else {
			return false;
		}
	}

	const handleChange = (event, id) => {    
		setQuestionsAnswered(
			questionsAnswered.map((question) => {
				if (question.id === id) {					
					if (question.type == 1 || question.type == 4) question.note = event.target.value;
					if (question.type == 2) question.text = event.target.value;
				}

				return {
					...question
				};
			})
		);
	};
  
	const handleSave = async () => {
		setIsSaving(true);
		try {		      
			await api.post(`/evaluations/${evaluation.id}/notes`, { notes: questionsAnswered });            			
      
			toast.success("Salvo com sucesso!");
			setIsSaving(false);
		} catch (err) {
			toast.error("Ops! Tivemos um erro ao tentar salvar suas notas");
			setIsSaving(false);
		}
	};

	const states = [
		{
			value: 0,			
			label: "Selecione uma nota"
		},
		{
			value: 6,
			label: "6 - Supera as expectativas para o nível de formação"
		},
		{
			value: 5,			
			label: "5 - Plenamente atendido"
		},
		{
			value: 4,			
			label: "4 - Bom desenvolvimento de modo geral"
		},
		{
			value: 3,	
			label: "3 - Parcialmente atendido, pode ser melhorado"		
		},
		{
			value: 2,			
			label: "2 - Falhou muito no atendimento ao item, o projeto precisa ser reelaborado ou profundas alterações precisam ser realizadas"
		},
		{
			value: 1,		
			label: "1 - Não contempla em nada o requisito"	
		}				
	];

	const windowWidth = window.innerWidth;
	let videoWidth = 640;
	let videoHeight = 390;

	if (windowWidth < 500)
	{
		videoWidth = windowWidth * 0.70;
		videoHeight = videoWidth * 0.61;
	}
	else if (windowWidth < 700)
	{
		videoWidth = windowWidth * 0.75;
		videoHeight = videoWidth * 0.61;
	}

	const opts = {
		height: videoHeight.toString(),
		width: videoWidth.toString(),
		playerVars: {
			autoplay: 0,
		},
	};


	return (
		<div className={classes.root}>
			<Grid
				container
				spacing={4}
			>			
				<Grid
					item
					lg={12}
					md={12}
					xl={12}
					xs={12}
				>
					<InterestConflictDialog 
						opened={dialogOpen}
						setOpen={setDialogOpen}
						evaluationId={evaluationId} 
						projectTitle={evaluation.project.title}
					/>																
					<Card
						className={classes.root}
					>            
						{loading?                        
							<CircularProgress/>:						
							<div>      							
								<div className={classes.cardHeader}>
									<Typography variant="h3">{(evaluation.project || {}).title}</Typography>     
									<Typography variant="h6">
										{`${(evaluation.project.area || {}).description} - ${(evaluation.project.subarea || {}).description}`}
									</Typography>                    
									{evaluation.project && evaluation.project.event && 
                <Typography variant="h6">
                	{evaluation.project.event.name} - {evaluation.project.phase === 1? "Projeto em andamento": "Projeto em concepção"}
                </Typography>}
								</div>							

								<Grid
									item						
									md={12}
									xs={12}
									className={classes.grid}
								> 
									<Typography variant="h4">Resumo do projeto</Typography>     
									<Typography variant="h6">{(evaluation.project || {}).abstract}</Typography>
								</Grid>

								<Grid
									item						
									md={12}
									xs={12}
									className={classes.grid}
								> 
									<Typography variant="h4">Links do projeto</Typography>     
									<Grid
										className={classes.links}
										container																			
									>
										{links.map((link) => (
											evaluation.project && evaluation.project[link.field] && 
                      <Link
                      	color="secondary"
                      	href={(evaluation.project || {})[link.field]}
                      	target="_blank"
                      	variant="h6"
                      	className={classes.link}
                      >
                      	{link.label}
                      </Link>  
										))}                  	
									</Grid>					
								</Grid>

								<Grid
									className={classes.grid}
									md={12}
									xs={12}
									item
								>
									<Typography variant="h4">Vídeo</Typography>     
									<Youtube videoId={(evaluation.project || {}).youtube_link} opts={opts}>
									</Youtube>								
								</Grid>

								<Divider />				
				
								<CardContent>
									<Grid
										container
										spacing={3}
									>
										<Typography variant="h3" className={classes.evaluateTitle}>Avaliação</Typography>     

										{questions.map((question) => (
											<Grid
												item
												key={question.id}
												md={12}
												xs={12}
											>
												<Typography variant="h5">{question.description}</Typography>
												<Typography variant="subtitle2">{question.auxiliary_text}</Typography>
												{question.type == 1? <TextField
													fullWidth
													margin="dense"									
													key={question.id}
													name="note"
													onChange={(event) => {handleChange(event, question.id);}}
													required
													select
													SelectProps={{ native: true }}
													value={questionsAnswered.some(x => x.id === question.id)? 
														questionsAnswered.find(x => x.id === question.id).note: 0}
													variant="outlined"
												>                  
													{states.map(option => (
														<option
															key={option.value}
															value={option.value}
														>
															{option.label}
														</option>
													))}
												</TextField>: 
												question.type == 2 ?
													<TextField 
														fullWidth 
														multiline 
														margin="dense" 
														name="note"
														onChange={(event) => {handleChange(event, question.id);}}
														rows={4} 
														value={questionsAnswered.some(x => x.id === question.id)? 
															questionsAnswered.find(x => x.id === question.id).text: ""} 
														variant="outlined"/>
														:
														<div>
															<Radio
																checked={isChecked(question.id, "1")}
																onClick={(event) => {handleChange(event, question.id);}}
																value = "1"
																name={"question" + question.id}
																inputProps={{
																	'aria-label': 'Radio Sim',
																	}}
															/>Sim
														
															<Radio
																checked={isChecked(question.id, "2")}
																onClick={(event) => {handleChange(event, question.id);}}
																value = "2"
																name={"question" + question.id}
																inputProps={{
																	'aria-label': 'Radio Não',
																	}}
															/>Não
														</div>
												}								
											</Grid>
							
				    ))} 						
									</Grid>
								</CardContent>
								<Divider />
								<CardActions className={classes.buttons}>
									<Button
										color="primary"
										variant="contained"
										disabled={isSaving}
										onClick={handleSave}
									>
            Salvar
									</Button>
								</CardActions>
							</div>
						}
					</Card>
					
				</Grid>
			</Grid>
		</div>
	);
};

export default ProjectEvaluate;
