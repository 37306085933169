import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import FilterListIcon from "@material-ui/icons/FilterList";
import api from "../../services/api";
import {	
	Select,
	MenuItem,
	Paper,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		borderRadius: "4px",
		alignItems: "center",
		padding: theme.spacing(1),
		display: "flex",
		flexBasis: 220
	},
	icon: {
		marginRight: theme.spacing(1),
		color: theme.palette.text.secondary
	},
	input: {
		flexGrow: 1,		
		marginLeft: "10px"
	},		
}));

const SelectArea = props => {
	const { setArea, projectArea, allOption, ...rest } = props;
	const [areaSelected, setAreaSelected] = useState(projectArea || 0);
	const [areas, setAreas] = useState([]);	
  
	function handleChange(event) {
		setAreaSelected(event.target.value);
		setArea(event.target.value);  		
	}
  
	useEffect(() => {
		async function loadAreas(){
			const response = await api.get("/areas");

			setAreas(response.data);
		}

		loadAreas();
	},[]);

	const classes = useStyles();

	return (
	
		<Paper className={classes.root}>
			<FilterListIcon></FilterListIcon>
			<Select
				className={classes.input}				
				fullWidth
				value={areaSelected}
				onChange={handleChange}
			>
				{allOption && (
					<MenuItem value={0}>
						<em>Todas as áreas</em>
					</MenuItem>
				)}				
				{areas.map((area) => (
					<MenuItem key={area.id} value={area.id}>{area.description}</MenuItem>
				))}
							
			</Select>
		</Paper>
	  
	);
};

SelectArea.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
	style: PropTypes.object,
	setArea: PropTypes.func,
	projectArea: PropTypes.number,
	allOption: PropTypes.bool
};

export default SelectArea;
