import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";

import { Password, AccountProfile, PersonalInformation } from "./components";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(4)
	}
}));

const Settings = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Grid
				container
				spacing={4}
				direction="row"
			> 
				<Grid
					item          					          
					sm={6}
					xs={12}          
				>
					<AccountProfile />
				</Grid>             
				<Grid
					item  										
					sm={6}
					xs={12}          
				>
					<PersonalInformation />
				</Grid>             
				<Grid
					item					
					sm={12}
					xs={12}         
				>
					<Password />
				</Grid>
			</Grid>
		</div>
	);
};

export default Settings;
