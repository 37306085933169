import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import api from "../../services/api";
import PerfectScrollbar from "react-perfect-scrollbar";
import { EvaluatorDialog } from "../../components";
import { SearchInput } from "components";
import { toast } from "react-toastify";
import {  
	Button,
	ButtonGroup,
	Tooltip,  
	Card,
	CardActions,
	CardContent,	
	Link,  
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	TablePagination,
	CircularProgress
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		flex: 1
	},
	content: {
		marginTop: theme.spacing(2),		
		display: "flex",
		justifyContent: "center"
	},	
	inner: {
		minWidth: 800
	},
	nameContainer: {
		display: "flex",
		alignItems: "center"
	},
	avatar: {
		marginRight: theme.spacing(2)
	},
	avatarCheckIcon: {
		backgroundColor: theme.palette.success.main,
		height: 22,
		width: 22,
		marginLeft: 5
	},
	avatarCancelIcon: {
		backgroundColor: theme.palette.error.main,
		height: 22,
		width: 22,
		marginLeft: 5
	},
	checkIcon: {
		height: 14,
		width: 14
	},
	cancelIcon: {
		height: 14,
		width: 14
	},
	actions: {
		justifyContent: "flex-end"
	},
	button: {
		color: "#fff",
		backgroundColor: theme.palette.primary.main,
		"&:hover": {
			background: "#3f51b5",
		},      
	},
	tooltip: {
		maxWidth: 500
	},
	row: {
		height: "42px",
		display: "flex",
		alignItems: "center",
		marginTop: theme.spacing(1)
	},
	spacer: {
		flexGrow: 1
	},
	importButton: {
		marginRight: theme.spacing(1)
	},
	exportButton: {
		marginRight: theme.spacing(1)
	},
	searchInput: {
		marginRight: theme.spacing(1)
	}
}));

const RequestList = () => {
	const classes = useStyles();

	const [evaluations, setEvaluations] = useState([]);
	const [totalEvaluations, setTotalEvaluations] = useState(0);	
	const [page, setPage] = useState(0);  
	const [dialogOpen, setDialogOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [consultantSelected, setConsultantSelected] = useState();
	const [search, setSearch] = useState("");  
  
	const handlePageChange = (event, page) => {		
		setPage(page);
	};

	async function loadEvaluations(page) {
		setLoading(true);
		const response = await api.get("evaluations/requests", {
			params: { page: page + 1, search },
		});

		setEvaluations(response.data.evaluations);
		setTotalEvaluations(response.data.total);
		setLoading(false);
	}
  
	async function handleRequest(evaluationId, status) {
		try {
			await api.put(`/evaluations/${evaluationId}`, {status});
      
			toast.success("Solicitação atualizada com sucesso!");      
      
			setEvaluations(evaluations.filter(e => e.id !== evaluationId));    			
		} catch(err){
			if (err.response && err.response.data.msg) {
				return toast.error(err.response.data.msg);
			}
			toast.error("Ops! Não conseguimos atualizar sua solicitação.");
		}
	};
  
	function handleDialog(consultant) {
		setConsultantSelected(consultant);
		setDialogOpen(true);
	}
  
	useEffect(() => {
		loadEvaluations(page);
	}, [page]);
  
	useEffect(() => {
		loadEvaluations(page);
	}, [search]);

	useEffect(() => {		
		loadEvaluations(0);
	},[]);
  

	return (
		<div className={classes.root}>
			<div className={classes.row}>
				<SearchInput
					className={classes.searchInput}
					placeholder="Procurar projeto"
					value={search}
					onChange={(event) => setSearch(event.target.value)}
				/>
			</div>
			<EvaluatorDialog opened={dialogOpen} setOpen={setDialogOpen} consultant={consultantSelected} />                           
			<div className={classes.content}>
				<Card
					className={classes.root}
				>					
					<CardContent className={classes.content}>	
						{loading? 
							<CircularProgress/>:
          			
							<PerfectScrollbar>
								<div className={classes.inner}>
									<Table>
										<TableHead>
											<TableRow>									
												<TableCell>Título</TableCell>									
												<TableCell>Avaliador</TableCell>									
												<TableCell>Aval. finalizadas</TableCell>									
												<TableCell></TableCell>							
											</TableRow>
										</TableHead>
										<TableBody>
											{evaluations.map(evaluation => (
												<TableRow
													className={classes.tableRow}
													hover
													key={evaluation.id}										
												>						
													<TableCell>
														<div className={classes.nameContainer}>												
															<Typography variant="body1">{evaluation.project.title}</Typography>												
														</div>
													</TableCell>					
													<TableCell>													
														<Tooltip 
															placement="top"
															title={`${evaluation.consultant.institution.description} - ${evaluation.consultant.titration.description}`} 
															className={classes.tooltip}>
															<Link 																				
																onClick={() => {handleDialog(evaluation.consultant);}}																													
																variant="h6">
																{evaluation.consultant.user.name}
															</Link>
														</Tooltip>
													</TableCell>
													<TableCell>
														{evaluation.project.Evaluations.length}
													</TableCell>
													<TableCell>
														<div>                            
														
															<ButtonGroup>
																<Tooltip title='Aprovar solicitação'>
																	<Button
																		onClick={() => {handleRequest(evaluation.id, 1);}}
																		color="primary"
																		variant="contained"
																		className={classes.button}                        
																		disabled={evaluation.project.evaluate}
																	>
																Aprovar
																	</Button>
																</Tooltip>			
																<Tooltip title="Reprovar solicitação">
																	<Button
																		variant="contained"
																		onClick={() => {handleRequest(evaluation.id, 0);}}
																	>
                                Reprovar                                
																	</Button>
																</Tooltip>
															</ButtonGroup>															
														
														</div>								
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</div>
							</PerfectScrollbar>
						}
					</CardContent>
					<CardActions className={classes.actions}>
						<TablePagination
							component="div"
							count={totalEvaluations}
							onChangePage={handlePageChange}							
							page={page}
							labelDisplayedRows={({from, to, count}) => `${from}-${to} de ${count}`}							
							rowsPerPage={10}
							rowsPerPageOptions={[10]}
						/>
					</CardActions>
				</Card>
			</div>
		</div>
	);
};

export default RequestList;
