import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import api from "../../services/api";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toast } from "react-toastify";
import { SearchInput, EvaluatorDialog } from "components";
import {  
	Button,
	ButtonGroup,
	Tooltip,
	Card,
	CardActions,
	CardContent,	
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,	
	TablePagination,
	Link
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3)
	},
	content: {
		marginTop: theme.spacing(2),
		padding: 0
	},	
	inner: {
		minWidth: 800
	},
	nameContainer: {
		display: "flex",
		alignItems: "center"
	},
	avatar: {
		marginRight: theme.spacing(2)
	},
	avatarCheckIcon: {
		backgroundColor: theme.palette.success.main,
		height: 22,
		width: 22,
		marginLeft: 5
	},
	avatarCancelIcon: {
		backgroundColor: theme.palette.error.main,
		height: 22,
		width: 22,
		marginLeft: 5
	},
	checkIcon: {
		height: 14,
		width: 14
	},
	cancelIcon: {
		height: 14,
		width: 14
	},
	actions: {
		justifyContent: "flex-end"
	},
	button: {
		backgroundColor: theme.palette.primary.main
	},
	row: {
		height: "42px",
		display: "flex",
		alignItems: "center",
		marginTop: theme.spacing(1)
	},
	spacer: {
		flexGrow: 1
	},
	importButton: {
		marginRight: theme.spacing(1)
	},
	exportButton: {
		marginRight: theme.spacing(1)
	},
	searchInput: {
		marginRight: theme.spacing(1)
	}
}));

const EvaluatorsList = () => {
	const classes = useStyles();

	const [evaluators, setEvaluators] = useState([]);
	const [totalEvaluators, setTotalEvaluators] = useState(0);	
	const [page, setPage] = useState(0);  	
	const [search, setSearch] = useState("");
	const [dialogOpen, setDialogOpen] = useState(false);
	const [consultantSelected, setConsultantSelected] = useState();
  
	const handlePageChange = (event, page) => {		
		setPage(page);
	};

	async function loadProjects(page) {    
		const response = await api.get("evaluators/pending", {
			params: { page: page + 1, search, pending: true },
		});

		setEvaluators(response.data.evaluators);
		setTotalEvaluators(response.data.total);
	}
  
	const handleClick = (consultant) => {
		setConsultantSelected(consultant);
		setDialogOpen(true);
	};
  
	const handleRequest = async (userId, value) => {
		try {
			await api.put(`/users/${userId}`, {
				active: value
			});
      
			setEvaluators(evaluators.filter(x => x.user.id !== userId));
      
			toast.success("Usuário atualizado com sucesso!");
		} catch (err){
			toast.error("Ops! Tivemos um problema ao atualizar o acesso do usuário");
		}
	} ; 
  
	useEffect(() => {
		loadProjects(page);
	}, [page]);
	
  
	useEffect(() => {
		loadProjects(page);
	}, [search]);

	useEffect(() => {		
		loadProjects(0);
	},[]);
  

	return (
		<div className={classes.root}>			
			<div className={classes.row}>
				<SearchInput
					className={classes.searchInput}
					placeholder="Procurar usuário por nome"
					value={search}
					onChange={(event) => setSearch(event.target.value)}
				/>				
			</div>			
			<EvaluatorDialog opened={dialogOpen} setOpen={setDialogOpen} consultant={consultantSelected} />																
      	<div className={classes.content}>
				<Card
					className={classes.root}
				>
					<CardContent className={classes.content}>				
						<PerfectScrollbar>
							<div className={classes.inner}>
								<Table>
									<TableHead>
										<TableRow>									
											<TableCell>Nome</TableCell>									
											<TableCell>Titulação</TableCell>									
											<TableCell></TableCell>							
										</TableRow>
									</TableHead>
									<TableBody>
										{evaluators.map(evaluator => (
											<TableRow
												className={classes.tableRow}
												hover
												key={evaluator.id}										
											>																		
												<TableCell>
													<div className={classes.nameContainer}>			                          									
														<Link 																				
															onClick={() => handleClick(evaluator)}																													
															variant="h6">
															{evaluator.user.name}
														</Link>														
													</div>
												</TableCell>					
												<TableCell>
													{evaluator.titration.description}
												</TableCell>
												<TableCell>
													<div>
														<ButtonGroup>
															<Tooltip title='Liberar acesso ao sistema'>
																<Button
																	onClick={() => {handleRequest(evaluator.user.id, 1);}}
																	color="primary"
																	variant="contained"
																	className={classes.button}                        																	
																>
																Liberar acesso
																</Button>
															</Tooltip>			
															<Tooltip title="Impedir o acesso">
																<Button
																	variant="contained"
																	onClick={() => {handleRequest(evaluator.user.id, 0);}}
																>
                                Bloquear                                
																</Button>
															</Tooltip>
														</ButtonGroup>														
													</div>								
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</div>
						</PerfectScrollbar>
					</CardContent>
					<CardActions className={classes.actions}>
						<TablePagination
							component="div"
							count={totalEvaluators}
							onChangePage={handlePageChange}							
							page={page}
							labelDisplayedRows={({from, to, count}) => `${from}-${to} de ${count}`}							
							rowsPerPage={10}
							rowsPerPageOptions={[10]}
						/>
					</CardActions>
				</Card>
			</div>
		</div>
	);
};

export default EvaluatorsList;
