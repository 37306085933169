import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import api from "../../services/api";
import history from "../../services/history";
import { toast } from "react-toastify";
import { Box, Checkbox, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
yesButton: {
	backgroundColor: theme.palette.success.dark + " !important",
	color: theme.palette.success.contrastText + " !important"
},
yesButtonDisabled: {
	color: theme.palette.error.main
},
noButton: {
	color: theme.palette.error.main
}
}));

export default function InterestConflictDialog(props) {
	const { opened, setOpen, evaluationId, projectTitle } = props;			
	const [checked, setChecked] = useState(false);

	const classes = useStyles();  

	const handleClose = async (value) => {
		try {
			await api.put(`evaluations/${evaluationId}/conflicts`, {
				has_interest_conflict: value
			});
			const message = value === 1? "Agradecemos a informação. Você será impossibilitado de avaliar esse projeto": 
				"Obrigado pela informação. Agora você já pode avaliar :)";

			toast.success(message);
			if (value === 1) history.push("/evaluations");
		} catch (err) {
			toast.error("Ops! Ocorreu um erro ao atualizar a avaliação. Tente novamente.");
		}

		setOpen(false);      
	};

	return (
		<div>			
			<Dialog
				open={opened}
				disableBackdropClick	
				disableEscapeKeyDown
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{`Conflito de interesse no projeto ${projectTitle}`}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
            			Você tem alguma relação com esse projeto que poderia tornar sua avaliação não isenta?
					</DialogContentText>
					<Box 
						display="flex"
						flexDirection="row"
						justifyContent="left"					
						alignItems="center"
						paddingLeft={1}							
					>
						<Checkbox 
							checked={checked} 
							onChange={() => setChecked(!checked)} 										
						/>
						<Typography style={{fontWeight: "100"}} variant="h6">
							Eu entendo que se houver conflito de interesse não poderei avaliar o projeto
						</Typography>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button 
						onClick={() => handleClose(0)} 
						className={
							`${checked ? classes.yesButton: classes.yesButtonDisabled}`
						} autoFocus
						disabled={!checked}>
            			Não
					</Button>
					<Button 
						onClick={() => handleClose(1)} 
						className={classes.noButton}
						disabled={!checked}>
            			Sim
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

InterestConflictDialog.propTypes = {
	opened: PropTypes.bool,
	setOpen: PropTypes.func,
	evaluationId: PropTypes.string,
	projectTitle: PropTypes.string
};
