import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import api from "../../services/api";
import moment from "moment";
import { SearchInput, TableRender } from "components";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3)
	},
	content: {
		marginTop: theme.spacing(2)
	},		
	avatarCheckIcon: {
		backgroundColor: theme.palette.success.main,
		height: 22,
		width: 22,
		marginLeft: 5
	},
	avatarCancelIcon: {
		backgroundColor: theme.palette.error.main,
		height: 22,
		width: 22,
		marginLeft: 5
	},
	checkIcon: {
		height: 14,
		width: 14
	},
	cancelIcon: {
		height: 14,
		width: 14
	},	
	row: {
		height: "42px",
		display: "flex",
		alignItems: "center",
		marginTop: theme.spacing(1)
	}	
}));

const ProfessorCertificateList = () => {
	const classes = useStyles();

	const [certificates, setCertificates] = useState([]);
	const [events, setEvents] = useState([]);
	const [totalEvaluations, setTotalEvaluations] = useState([]);	
	const [page, setPage] = useState(0);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const columns = ["Nome", "Evento", "Total de horas", "Emitido em"];
	const values = [
		(data) => data.name,
		(data) => {
			const findEvent = events.find((event) => event.id === data.event_id);

			if (!findEvent) return "Evento";

			return findEvent.name;
		},
		() => "40 horas",
		() => moment(events[0].certificate_date).utc().format("DD/MM/YYYY")
	];
  
	async function loadCertificates() {
		setLoading(true);
		const response = await api.get("certificates/projects");		

		setCertificates(response.data.result);
		setEvents(response.data.events);
		setTotalEvaluations(10);
		setLoading(false);
	}

	useEffect(() => {		
		loadCertificates();
	},[]);	
  
	const handlePageChange = (event, page) => {
		setPage(page);
	};	
  
	const handleClick = async (object) => {
		const data = { user_id: object.user.id };

		const response = await api.post(`/certificates/${data.user_id}/projects`, 
			data,
			{ responseType: "blob" });		
    
		const file = new Blob(
			[response.data], 
			{type: "application/pdf"});
		
		const fileURL = URL.createObjectURL(file);		
		window.open(fileURL);
	};

	return (
		<div className={classes.root}>
			<div className={classes.row}>
				<SearchInput
					className={classes.searchInput}
					placeholder="Procurar evento por título"
					value={search}
					onChange={(event) => setSearch(event.target.value)}
				/>
			</div>
			<TableRender
				loading={loading}
				data={certificates}
				columns={columns}
				values={values}
				buttonOptions={{            
					tooltip: "Clique para baixar",
					onClick: (certificate) => handleClick(certificate),
					title: "Baixar"					
				}}
				tablePaginationOptions={{            
					total: totalEvaluations,
					onChangePage: (event, page) => handlePageChange(event, page),
					page: page
				}}
			/>														
		</div>
	);
};

export default ProfessorCertificateList;
