import produce from "immer";

const INITIAL_STATE = {
	selected: null,
};

export default function project(state = INITIAL_STATE, action) {
	return produce(state, (draft) => {
		switch (action.type) {
		case "@project/SELECT_PROJECT": {
			draft.selected = action.payload.data;
			break;
		}		
		case "@auth/SIGN_OUT": {
			draft.selected = null;
			break;
		}
		default:
		}
	});
}
