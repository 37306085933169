import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Grid, CircularProgress } from "@material-ui/core";
import api from "../../services/api";

import {	
	DashboardCard,	
	LatestEvaluations,
} from "./components";
import { USER_TYPE } from "enums/user-type";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(4)
	},
	grid: {
		justifyContent: "center",
		padding: 10
	}
}));

const Dashboard = () => {
	const classes = useStyles();
	const [boards, setBoards] = useState([]);
	const userState = useSelector((state) => state.user.profile);
  
	useEffect(() => {
		async function loadDashboard() {
			const response = await api.get("/dashboard");

			setBoards(response.data.boards);
		};

		loadDashboard();
	}, []);

	return (
		<div className={classes.root}>
			<Grid
				className={classes.grid}
				container
				spacing={4}
			>
				{boards.length === 0? 
					<CircularProgress />: 
					boards.map((board) => (
						<Grid    
							key={board.title}          
							item	
							sm={4}				
							xl={4}
							xs={12}
						>
							<DashboardCard value={board.total} icon={board.icon} title={board.title} />
						</Grid>
					))          
				}							
				{userState.type === USER_TYPE.ADMIN && <Grid
					item					
					xs={12}
				>
					<LatestEvaluations />
				</Grid>}				
			</Grid>
		</div>
	);
};

export default Dashboard;
