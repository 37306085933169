import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";

export default function ConfirmRequest(props) {
	const { opened, handleClose } = props;				

	return (
		<div>			
			<Dialog
				open={opened}
				disableBackdropClick	
				disableEscapeKeyDown
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Solicitação de avaliação</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
            Você leu o resumo disponibilizado e sentiu-se apto a avaliar o trabalho?            
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose(0)} color="primary">
            Não
					</Button>
					<Button onClick={() => handleClose(1)} color="primary" autoFocus>
            Sim
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

ConfirmRequest.propTypes = {
	opened: PropTypes.bool,
	setOpen: PropTypes.func,
	handleClose: PropTypes.func,
	evaluationId: PropTypes.string,
	projectTitle: PropTypes.string
};
