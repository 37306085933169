import produce from "immer";

const INITIAL_STATE = {
	profile: null,
	loading: false,
};

export default function user(state = INITIAL_STATE, action) {
	return produce(state, (draft) => {
		switch (action.type) {
		case "@auth/SIGN_IN_SUCCESS": {
			draft.profile = action.payload.user;
			break;
		}
		case "@user/UPDATE_AVATAR_REQUEST": {
			draft.loading = true;
			break;
		}
		case "@user/UPDATE_AVATAR_SUCCESS": {
			draft.profile.avatar = "";
			draft.profile.avatar = action.payload.avatar;
			draft.loading = false;
			break;
		}
		case "@user/UPDATE_PROFILE_SUCCESS": {
			draft.profile = action.payload.profile;
			break;
		}
		case "@user/UPDATE_USER_SUCCESS": {
			draft.profile = Object.assign(draft.profile, {... action.payload.profile });
			break;
		}
		case "@auth/SIGN_OUT": {
			draft.profile = null;
			break;
		}
		default:
		}
	});
}
