import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import {
	Box,
	Grid,
	Card,		
	Divider,	
	Link,
	Button,
	TextField,
	CircularProgress,	
	Typography,
} from "@material-ui/core";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2),
		justifyContent: "center",
		display: "flex"
	},
	card: {
		padding: theme.spacing(2),
		justifyContent: "center",
		display: "flex"
	},
	grid: {
		padding: 16,
		textAlign: "center"		
	},
	cardHeader: {
		textAlign: "center",		
	},
	links: {
		justifyContent: "center"
	},
	link: {
		padding: 10,		
	},	
	evaluateTitle: {
		textAlign: "center",
		padding: 16,
		flex: 1
	},
	checkIcon: {
		fill: "green"
	},
	cancelIcon: {
		fill: "red"
	},
	titlePage: {
		textAlign: "left",
		[theme.breakpoints.down("xs")]: {
			textAlign: "center"
		}
	},
	statusText: {
		display: "flex",
		[theme.breakpoints.down("xs")]: {
			textAlign: "center"
		}
	},
	status: {
		marginLeft: "auto",
		marginRight: "4px"
	},
	reactTagInput: {
		background: "transparent",
		border: 0,
		color: "#777",
		fontFamily: "Roboto",
		fontSize: "13px",
		fontWeight: 400,
		marginBottom: "6px",
		marginTop: "1px",
		outline: "none",
		padding: "5px",
		width: "280px"
	}
}));

const FinalStepProject = (props) => {
	const classes = useStyles();
	const [project, setProject] = useState(null);			
	const projectId = props.match.params.id;
	const [loading, setLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);	
	const [savingFile, setSavingFile] = useState({file: "", isSaving: false});  
	const [count, setCountWords] = useState("");  
	const [confirmed, setConfirmed] = useState(false); 	
    
	useEffect(() => {		
		async function loadProject() {
			const response = await api.get(`/projects/${projectId}`);      

			setProject(response.data);        		
      
			verifyConfirmStatus(response.data);      

			setCountWords(splitWords(response.data.abstract || "").length);
      
			setLoading(false);						
		};		
		
		loadProject();						
	}, []);	  
  
	const verifyConfirmStatus = (project) => {		
		const hasAllRequiredFields = 
      project.evaluation_video_link && project.evaluation_video_link.length > 0 &&
      project.abstract && project.poster_link && project.keywords && project.keywords.split(", ").length >= 3;        

		setConfirmed(hasAllRequiredFields);    					
	};
  
	const splitWords = (text) => {
		let countWords = text.replace(/(^\s*)|(\s*$)/gi,"")
			.replace(/[ ]{2,}/gi," ")
			.replace(/\n /,"\n");    
      
		countWords = countWords.length === 0 ? [] : countWords.split(" ");      
    
		return countWords;
	};
  
	const handleSave = async () => {		
		try {						
			if (count < 150) {
				return toast.error("O resumo precisa ter no mínimo 150 palavras.");
			}

			setIsSaving(true);

			await api.put(`/projects/${project.id}`, {project});                
      
			verifyConfirmStatus(project);
      
			toast.success("Salvo com sucesso!");
      
			setIsSaving(false);    			      
		} catch (err) {
			setIsSaving(false);
      
			if (err.response && err.response.data.msg) {
				return toast.error(err.response.data.msg);
			}
      
			toast.error("Ops! Tivemos um erro ao tentar salvar suas alterações");			
		}
	};
  
	const handleFile = async (e, type) => {
		try {
			const data = new FormData();
			data.append("file", e.target.files[0]);      
			data.append("type", type);      
      
			setSavingFile({file: type, isSaving: true});
      
			const response = await api.post(`projects/${projectId}/files`, data);			                
      
			setProject({
				...project,
				[type]: response.data.location
			});    			
      
			verifyConfirmStatus(project);

			setSavingFile({file: "", isSaving: false});

			toast.success("Eba! Seu arquivo já foi atualizado.");
		} catch (err) {
			setSavingFile({file: "", isSaving: false});
      
			if (err.response && err.response.data.msg) {
				return toast.error(err.response.data.msg);
			}

			toast.error("Ops! Tivemos um erro ao tentar atualizar o arquivo.");
		} 
	};		
  
	const handleChange = (e, initial) => {		
		const maxNumberOfWords = 250;  		

		const value = initial ? initial : e.target.value;

		const splittedWords = splitWords(value);
    
		if (splittedWords.length > maxNumberOfWords) {
			e.target.value = splittedWords.slice(0, maxNumberOfWords).join(" ");
			setCountWords(maxNumberOfWords);
			return;
		}    		
        
		setCountWords(splittedWords.length);
	};  
  
	const handleChangeTag = (tags) => {
		setProject({ ...project, keywords: tags.join(", ") });		
	};

	return (
		<div className={classes.root}>
			<Grid
				container
				spacing={4}
			>			
				<Grid
					item					
					xs={12}
				>																				
					<Card className={classes.card}>            
						{loading?                        
							<CircularProgress/>:		
								
							<Grid xs={12} className={classes.cardHeader}>
								<Typography variant="h4">Etapa final</Typography>
								<Typography variant="subtitle2" style={{ marginTop: "6px", marginBottom: "6px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at odio ligula. Ut malesuada elementum ultricies. Donec mollis pellentesque sapien in varius. Phasellus aliquet mauris vitae risus accumsan, a dapibus ex semper. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nunc nec hendrerit dolor, venenatis placerat odio. In et augue fermentum, commodo mauris sed, dapibus tellus. Mauris mollis velit ut urna rhoncus, vel suscipit lorem venenatis. Suspendisse a leo consectetur, sagittis ipsum et, elementum tellus. Proin commodo lectus purus, eu condimentum ligula condimentum et. Cras rutrum odio erat, a ornare mauris gravida et. Quisque sed odio at arcu mattis lobortis quis eu ante. Phasellus ultricies, odio at vulputate vulputate, massa libero posuere odio, nec aliquam nunc tortor porttitor turpis. Integer ultricies luctus leo. Nam eu tempus tortor. Etiam dictum ac elit nec efficitur.</Typography>
									
								<Divider />	
								
								<Box display="flex" width="100%" flexDirection="column" mt={1}>                											
									<Box my={4}>
										<Typography variant="h4">{project.title}</Typography>     
										<Typography variant="h6">
											{project.area.description} - {project.subarea.description}
										</Typography>                    
										{project && project.event && 
										<Typography variant="h6"> {project.event.name} - {project.phase === 1? "Projeto em andamento": "Projeto em concepção"}
										</Typography>}
									</Box>			                

									<Divider />	
																									
									<Box display="flex" flexDirection="column" className={classes.grid}> 											
										<Box display="flex" flexDirection="row">
											<Grid container>
												<Grid xs={12} md={6} >
													<Typography variant="h4" className={classes.titlePage}>Informações finais</Typography>  
												</Grid>   										
												<Grid xs={12} md={6}>
													<div className={classes.statusText}>
														<Typography variant="subtitle1" className={classes.status}>{confirmed ? "Todas as informações enviadas" : "Algumas informações ainda não foram enviadas"}</Typography>
														{confirmed ? <CheckCircleIcon className={classes.checkIcon} />: <CancelIcon className={classes.cancelIcon} />} 
													</div>
												</Grid>  
											</Grid> 
										</Box>										
										<Box display="flex" textAlign="start" width="100%" flexDirection="column" my={1}>
											<Typography variant="h6">Link do vídeo</Typography>										
											<TextField                																										
												margin="dense"
												name="evaluationVideo"																																							
												variant="outlined"
												onChange={(e) => setProject({ ...project, evaluation_video_link: e.target.value })}                    
												value={project.evaluation_video_link}    										
											/>    
										</Box>															                			
										<Box display="flex" width="100%" textAlign="start" flexDirection="column" my={1}>
											<Box display="flex" flexDirection="row">
											  <Typography variant="h6">
													<span translate="no">Banner</span>
												</Typography>
												{project["poster_link"] && 
                          <>
                            <span style={{ marginLeft: "2px", marginRight: "2px" }}>-</span>
                            <Link 																											
                            	href={project["poster_link"]}    
                            	target="_blank"													
                            	color="secondary"
                            	variant="h6"> Clique aqui para ver o arquivo
                            </Link>
                          </>            											
												}                        											
											</Box>
											{savingFile.file === "poster_link" && savingFile.isSaving === true? 
												<CircularProgress size={20}  />:
												<TextField
													fullWidth													
													margin="dense"
													disabled={false}																																																		
													variant="outlined"
													type="file"													
													onChange={(e) => handleFile(e, "poster_link")}
													inputProps={{
														"accept":"application/pdf"
													}}										
												/>       									               
											}
										</Box>		 
										<Box display="flex" width="100%" textAlign="start"  flexDirection="column" my={1}>																
											<Typography variant="h6">Resumo (150 a 250 palavras)</Typography>
											<TextField 
												fullWidth 
												multiline 
												margin="dense" 
												name="abstract"
												onChange={(event) => {handleChange(event); setProject({ ...project, abstract: event.target.value });}}                							
												onPaste={(event) => {handleChange(event); setProject({ ...project, abstract: event.target.value });}}
												rows={12}                           
												variant="outlined"
												value={project.abstract}
											/>
											<Typography variant="caption">{count}/250 palavras</Typography>
										</Box>
										<Box display="flex" width="100%" textAlign="start" flexDirection="column" my={1} mb={2}>																
											<Typography variant="h6">Palavras chaves (min. 3 e máx. 5)</Typography>
											<Box mt={1}>
												<TagsInput
													maxTags={5}
													value={project.keywords ? project.keywords.split(", ") : []}
													onChange={handleChangeTag}													
													inputProps={{
														className: classes.reactTagInput,
														placeholder: "Digite CADA palavra chave e pressione ENTER"
													}}
												/>												
											</Box>
										</Box>                    
										<Box display="flex" justifyContent="flex-end">
											<Button                                                                                  
												color="primary"
												variant="contained"
												disabled={isSaving}
												onClick={handleSave}>
											Salvar
											</Button>
										</Box>
									</Box>								                		                											          	
								</Box>
							</Grid>								
						}
					</Card>
					
				</Grid>
			</Grid>
		</div>
	);
};

export default FinalStepProject;
