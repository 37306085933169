import React, { Component } from "react";
import { Router } from "react-router-dom";
import { Chart } from "react-chartjs-2";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@material-ui/styles";
import validate from "validate.js";
import "./config/ReactotronConfig";
import { chartjs } from "./helpers";
import theme from "./theme";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/scss/index.scss";
import validators from "./common/validators";
import Routes from "./routes/index";
import { store, persistor } from "./store";
import history from "./services/history";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
	draw: chartjs.draw,
});

validate.validators = {
	...validate.validators,
	...validators,  
};

export default class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<ThemeProvider theme={theme}>
						<Router history={history}>          
							<Routes />                 
							<ToastContainer autoClose={3000} limit={2}></ToastContainer>
						</Router>                
					</ThemeProvider>
				</PersistGate>
			</Provider>
		);
	}
}
