import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Avatar, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { USER_TYPE } from "enums/user-type";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		minHeight: "fit-content"
	},
	avatar: {
		width: 60,
		height: 60
	},
	name: {
		marginTop: theme.spacing(1),
		textAlign: "center"
	}
}));

const Profile = props => {
	const { className, ...rest } = props;
	const userState = useSelector((state) => state.user.profile);

	const classes = useStyles();
  
	let biography;

	switch (userState.type) {
	case USER_TYPE.EVALUATOR:
		biography = "Avaliador";
		break;
	case USER_TYPE.ADMIN:
		biography = "Comitê Científico";
		break;
	case USER_TYPE.ADVISOR:
		biography = "Professor";
		break;
	case USER_TYPE.STUDENT:
		biography = "Aluno";
		break;
	}

	const user = {
		name: userState.nickname && userState.nickname.length > 0? userState.nickname : userState.name,		
		bio: biography
	};

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Avatar				
				className={classes.avatar}
				component={RouterLink}
				src={`${userState.avatar}?${new Date().getTime()}`}
				to="/settings"
			/>
			<Typography
				className={classes.name}
				variant="h4"
			>
				{user.name}
			</Typography>
			<Typography variant="body2">{user.bio}</Typography>
		</div>
	);
};

Profile.propTypes = {
	className: PropTypes.string
};

export default Profile;
