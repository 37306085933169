import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import api from "../../services/api";
import history from "../../services/history";
import { SearchInput, TableRender } from "components";
import { Avatar, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3)
	},
	content: {
		marginTop: theme.spacing(2)
	},		
	avatarCheckIcon: {
		backgroundColor: theme.palette.success.main,
		height: 22,
		width: 22,
		marginLeft: 5
	},
	avatarCancelIcon: {
		backgroundColor: theme.palette.error.main,
		height: 22,
		width: 22,
		marginLeft: 5
	},
	checkIcon: {
		height: 14,
		width: 14
	},
	cancelIcon: {
		height: 14,
		width: 14
	},	
	row: {
		height: "42px",
		display: "flex",
		alignItems: "center",
		marginTop: theme.spacing(1)
	}	
}));

const ProjectList = () => {
	const classes = useStyles();

	const [evaluations, setEvaluations] = useState([]);
	const [totalEvaluations, setTotalEvaluations] = useState([]);	
	const [page, setPage] = useState(0);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const columns = ["Título", "Área", "Status"];
	const values = [
		(evaluation) => (
			<div style={{ display: "flex" }}>      
				<Typography variant="body1">{evaluation.project.title}</Typography>
				{evaluation.finished? 
					<Avatar className={classes.avatarCheckIcon}>
						<CheckCircleIcon className={classes.checkIcon} />
					</Avatar>:
					<Avatar className={classes.avatarCancelIcon}>
						<CancelIcon className={classes.cancelIcon} />
					</Avatar>      											
				}
			</div>
		),
		(evaluation) => evaluation.project.area.description,
		(evaluation) => {      
			return evaluation.has_interest_conflict? "Conflito de interesse":  (evaluation.finished? "Avaliado": "Pendente");	
		}
	];
  
	async function loadEvaluations() {
		setLoading(true);
		const response = await api.get("evaluations", {
			params: { page: page + 1, search },
		});

		setEvaluations(response.data.evaluations);
		setTotalEvaluations(response.data.total);
		setLoading(false);
	}

	useEffect(() => {		
		loadEvaluations();
	},[]);
  
	useEffect(() => {
		loadEvaluations();
	}, [search]);
  
	useEffect(() => {
		loadEvaluations();
	}, [page]);
  
	const handlePageChange = (event, page) => {
		setPage(page);
	};	
  
	const handleClick = (id) => {
		history.push(`/projects/${id}/evaluate`, {evaluationId: id});
	};

	return (
		<div className={classes.root}>
			<div className={classes.row}>
				<SearchInput
					className={classes.searchInput}
					placeholder="Procurar projeto por título"
					value={search}
					onChange={(event) => setSearch(event.target.value)}
				/>
			</div>
			<TableRender
				loading={loading}
				data={evaluations}
				columns={columns}
				values={values}
				buttonOptions={{            
					tooltip: "Clique para avaliar",
					onClick: (evaluation) => handleClick(evaluation.id),
					title: "Avaliar",
					disabled: (evaluation) => evaluation.has_interest_conflict
				}}
				tablePaginationOptions={{            
					total: totalEvaluations,
					onChangePage: (event, page) => handlePageChange(event, page),
					page: page
				}}
			/>														
		</div>
	);
};

export default ProjectList;
