import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import api from "../../services/api";
import PerfectScrollbar from "react-perfect-scrollbar";
import { SearchInput, SelectArea, EvaluatorDialog } from "components";
import {  
	Button,
	Tooltip,
	Card,
	Grid,
	CardActions,
	CardContent,	
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	TablePagination
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3)
	},
	content: {
		marginTop: theme.spacing(1),
		padding: 0
	},	
	inner: {
		minWidth: 800
	},
	nameContainer: {
		display: "flex",
		alignItems: "center"
	},
	avatar: {
		marginRight: theme.spacing(2)
	},
	avatarCheckIcon: {
		backgroundColor: theme.palette.success.main,
		height: 22,
		width: 22,
		marginLeft: 5
	},
	avatarCancelIcon: {
		backgroundColor: theme.palette.error.main,
		height: 22,
		width: 22,
		marginLeft: 5
	},
	checkIcon: {
		height: 14,
		width: 14
	},
	cancelIcon: {
		height: 14,
		width: 14
	},
	actions: {
		justifyContent: "flex-end"
	},
	button: {
		backgroundColor: theme.palette.primary.main
	},
	row: {
		display: "flex",
		alignItems: "center",
	},
	spacer: {
		flexGrow: 1
	},
	importButton: {
		marginRight: theme.spacing(1)
	},
	exportButton: {
		marginRight: theme.spacing(1)
	},
	itemMargin: {
		margin: theme.spacing(.5),
	},
}));

const EvaluatorsList = () => {
	const classes = useStyles();

	const [evaluators, setEvaluators] = useState([]);  
	const [totalEvaluators, setTotalEvaluators] = useState(0);	
	const [area, setArea] = useState(0);
	const [page, setPage] = useState(0);  	
	const [search, setSearch] = useState("");
	const [dialogOpen, setDialogOpen] = useState(false);
	const [consultantSelected, setConsultantSelected] = useState();
  
	const handlePageChange = (event, page) => {		
		setPage(page);
	};

	async function loadProjects(page) {    
		const response = await api.get("evaluators", {
			params: { page: page + 1, area, search },
		});

		setEvaluators(response.data.evaluators);
		setTotalEvaluators(response.data.total);
	}
  
	const handleClick = (consultant) => {
		setConsultantSelected(consultant);
		setDialogOpen(true);
	};
  
	useEffect(() => {
		loadProjects(page);
	}, [page]);	
  
	useEffect(() => {
		loadProjects(page);
	}, [search]);
  
	useEffect(() => {
		loadProjects(page);
	}, [area]);
	
	useEffect(() => {		
		loadProjects(0);
	},[]);
  

	return (
		<div className={classes.root}>			
			<Grid container className={classes.row}>
				<Grid item xs={12} sm={12} md={5} className={classes.itemMargin}>
					<SearchInput
						placeholder="Procurar avaliador por nome"
						value={search}
						onChange={(event) => setSearch(event.target.value)}
					/>				
				</Grid>			
				<Grid item xs={12} sm={10} md={6} className={classes.itemMargin}>
					<SelectArea allOption setArea={setArea}></SelectArea>
				</Grid>			
			</Grid>			
			<EvaluatorDialog opened={dialogOpen} setOpen={setDialogOpen} consultant={consultantSelected} />																
			<div className={classes.content}>
				<Card
					className={classes.root}
				>
					<CardContent className={classes.content}>				
						<PerfectScrollbar>
							<div className={classes.inner}>
								<Table>
									<TableHead>
										<TableRow>									
											<TableCell>Nome</TableCell>									
											<TableCell>Titulação</TableCell>									
											<TableCell></TableCell>							
										</TableRow>
									</TableHead>
									<TableBody>
										{evaluators.map(evaluator => (
											<TableRow
												className={classes.tableRow}
												hover
												key={evaluator.id}										
											>																		
												<TableCell>
													<div className={classes.nameContainer}>												
														<Typography variant="body1">{evaluator.user.name}</Typography>
												
													</div>
												</TableCell>					
												<TableCell>
													{evaluator.titration.description}
												</TableCell>
												<TableCell>
													<div>
														<Tooltip title={"Ver mais informações sobre o avaliador"}>
															<Button
																onClick={() => {handleClick(evaluator);}}
																color="primary"
																variant="contained"
																className={classes.button}>
																Ver
															</Button>
														</Tooltip>			
													</div>								
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</div>
						</PerfectScrollbar>
					</CardContent>
					<CardActions className={classes.actions}>
						<TablePagination
							component="div"
							count={totalEvaluators}
							onChangePage={handlePageChange}							
							page={page}
							labelDisplayedRows={({from, to, count}) => `${from}-${to} de ${count}`}							
							rowsPerPage={10}
							rowsPerPageOptions={[10]}
						/>
					</CardActions>
				</Card>
			</div>
		</div>
	);
};

export default EvaluatorsList;
