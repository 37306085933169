import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { toast } from "react-toastify";
import Youtube from "react-youtube";
import {
	Box,
	Grid,
	Card,
	CardActions,
	Divider,
	Link,
	Button,
	TextField,
	CircularProgress,
	Typography,
} from "@material-ui/core";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
		justifyContent: "center",
	},
	grid: {
		padding: 8,
		textAlign: "center",
		border: "1px solid #eee",
	},
	cardHeader: {
		textAlign: "center",
		padding: 16,
	},
	links: {
		justifyContent: "center",
	},
	link: {
		padding: 10,
	},
	evaluateTitle: {
		textAlign: "center",
		padding: 16,
		flex: 1,
	},
	buttons: {
		display: "block",
		textAlign: "center",
	},
}));

const EditProject = (props) => {
	const classes = useStyles();
	const [project, setProject] = useState({});
	const [states, setStates] = useState([]);
	const [cities, setCities] = useState([]);
	const projectId = props.match.params.id;
	const [loading, setLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const [isLoadingCities, setIsLoadingCities] = useState(false);
	const [savingFile, setSavingFile] = useState({ file: "", isSaving: false });

	async function loadCities(stateId) {
		setIsLoadingCities(true);
		const response = await api.get(`/cities/${stateId}`);

		setCities(response.data);
		setIsLoadingCities(false);
	}

	useEffect(() => {
		async function loadProject() {
			const response = await api.get(`/projects/${projectId}`);

			setProject(response.data);
			if (response.data.state) await loadCities(response.data.state.id);

			setLoading(false);
		}
		async function loadStates() {
			const response = await api.get("/states");

			setStates(response.data);
		}

		loadStates();
		loadProject();
	}, []);

	const handleSave = async () => {
		try {
			if (!project.state || !project.city) {
				return toast.error("Os campos de localidade são obrigatórios.");
			}
			setIsSaving(true);

			await api.put(`/projects/${project.id}`, { project });

			toast.success("Salvo com sucesso!");
			setIsSaving(false);
		} catch (err) {
			let errorMessage = "Ops! Tivemos um erro ao tentar salvar o projeto";

			if (err.response && err.response.data.msg) {
				errorMessage = err.response.data.msg;
			}

			toast.error(errorMessage);
			setIsSaving(false);
		}
	};

	const handleFile = async (e, type) => {
		try {
			const data = new FormData();
			data.append("file", e.target.files[0]);
			data.append("type", type);

			setSavingFile({ file: type, isSaving: true });

			const response = await api.post(`projects/${projectId}/files`, data);

			setProject({
				...project,
				[type]: response.data.location,
			});

			setSavingFile({ file: "", isSaving: false });

			toast.success("Eba! Seu arquivo já foi atualizado.");
		} catch (err) {
			setSavingFile({ file: "", isSaving: false });

			if (err.response && err.response.data.msg) {
				return toast.error(err.response.data.msg);
			}

			toast.error("Ops! Tivemos um erro ao tentar atualizar o arquivo.");
		}
	};

	const handleState = async (event, newValue) => {
		if (!project.state || (newValue && project.state.id !== newValue.id)) {
			setProject({
				...project,
				city: {},
				state: newValue,
			});
			await loadCities(newValue.id);
		}
	};

	const handleCity = async (event, newValue) => {
		setProject({ ...project, city: newValue });
	};

	const documents = [
		{
			label: "Formulário de responsabilidade",
			name: "responsibility_link",
			disabled: true,
		},
		{
			label: "Projeto de pesquisa",
			name: "research_project_link",
			disabled: false,
		},
		{
			label: "Resumo expandido",
			name: "expanded_abstract_link",
			disabled: false,
		},
		{
			label: "Projeto de continuidade",
			name: "continuity_link",
			disabled: false,
		},
		{ label: "Formulários especiais", name: "special_link", disabled: false },
	];

	const windowWidth = window.innerWidth;
	let videoWidth = 640;
	let videoHeight = 390;

	if (windowWidth < 500) {
		videoWidth = windowWidth * 0.7;
		videoHeight = videoWidth * 0.61;
	} else if (windowWidth < 700) {
		videoWidth = windowWidth * 0.75;
		videoHeight = videoWidth * 0.61;
	}

	const opts = {
		height: videoHeight.toString(),
		width: videoWidth.toString(),
		playerVars: {
			autoplay: 0,
		},
	};

	return (
		<div className={classes.root}>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Card className={classes.root}>
						{loading ? (
							<CircularProgress />
						) : (
							<form autoComplete="no" noValidate>
								<div className={classes.cardHeader}>
									<Typography variant="h3">{project.title}</Typography>
									<Typography variant="h6">
										{project.area.description} - {project.subarea.description}
									</Typography>
									{project && project.event && (
										<Typography variant="h6">
											{project.event.name} -{" "}
											{project.phase === 1
												? "Projeto em andamento"
												: "Projeto em concepção"}
										</Typography>
									)}
								</div>

								<Grid item md={12} xs={12} className={classes.grid}>
									<Typography variant="h4">Resumo do projeto</Typography>
									<Typography variant="h6">{project.abstract}</Typography>
								</Grid>

								<Grid className={classes.grid} md={12} xs={12} item>
									<Typography variant="h4">Vídeo</Typography>
									<Youtube
										videoId={String(project.youtube_link)}
										opts={opts}
									></Youtube>
								</Grid>

								<Divider />

								<Box
									display="flex"
									flexDirection="column"
									className={classes.grid}
								>
									<Typography variant="h4">Localidade</Typography>
									<Box textAlign="start">
										<Typography variant="h6">País</Typography>
										<TextField
											fullWidth
											disabled
											margin="dense"
											name="note"
											variant="outlined"
											value={project.country.description}
										/>
										<Typography variant="h6">Estado</Typography>
										<Autocomplete
											id="state-combobox"
											options={states}
											style={{ width: "100%" }}
											getOptionLabel={(option) => option.description}
											onChange={handleState}
											value={project.state}
											renderInput={(params) => {
												params.inputProps.autoComplete = "no";
												return (
													<TextField
														{...params}
														fullWidth
														margin="dense"
														variant="outlined"
													/>
												);
											}}
										/>

										<Typography variant="h6">Cidade</Typography>
										{isLoadingCities ? (
											<CircularProgress size={20} />
										) : (
											<Autocomplete
												id="state-combobox"
												options={cities}
												style={{ width: "100%" }}
												getOptionLabel={(option) => option.description}
												onChange={handleCity}
												value={project.city}
												renderInput={(params) => {
													params.inputProps.autoComplete = "no";
													return (
														<TextField
															{...params}
															fullWidth
															margin="dense"
															variant="outlined"
														/>
													);
												}}
											/>
										)}
									</Box>
								</Box>
								<Box
									display="flex"
									flexDirection="column"
									className={classes.grid}
								>
									<Typography variant="h4">
                    Informações para o evento
									</Typography>
									<Box
										display="flex"
										width="100%"
										textAlign="start"
										flexDirection="column"
										my={1}
									>
										<Typography variant="h6">Link do vídeo</Typography>
										<TextField
											disabled
											margin="dense"
											name="note"
											variant="outlined"
											value={`https://www.youtube.com/watch?v=${project.youtube_link}`}
										/>
									</Box>
									<Box
										display="flex"
										width="100%"
										textAlign="start"
										flexDirection="column"
										my={1}
									>
										<Typography variant="h6">Link da música</Typography>
										<TextField
											fullWidth
											margin="dense"
											name="note"
											variant="outlined"
											value={project.music_link}
										/>
									</Box>
									{documents.map((document) => (
										<Box
											key={document.name}
											display="flex"
											width="100%"
											textAlign="start"
											flexDirection="column"
											my={1}
										>
											<Typography variant="h6">
												{document.label} -{" "}
												<Link
													href={project[document.name]}
													target="_blank"
													color="secondary"
													variant="h6"
												>
                          Clique aqui para ver o arquivo
												</Link>
											</Typography>
											{savingFile.file === document.name &&
                      savingFile.isSaving === true ? (
													<CircularProgress size={20} />
												) : (
													<TextField
														fullWidth
														margin="dense"
														disabled={document.disabled}
														variant="outlined"
														type="file"
														onChange={(e) => handleFile(e, document.name)}
														inputProps={{
															accept: "application/pdf",
														}}
													/>
												)}
										</Box>
									))}
								</Box>
								<Divider />
								<CardActions className={classes.buttons}>
									<Button
										color="primary"
										variant="contained"
										disabled={isSaving}
										onClick={handleSave}
									>
                    Salvar
									</Button>
								</CardActions>
							</form>
						)}
					</Card>
				</Grid>
			</Grid>
		</div>
	);
};

export default EditProject;
