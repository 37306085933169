import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import { makeStyles } from "@material-ui/styles";
import palette from "theme/palette";
import {
	Card,
	CardHeader,
	CardContent,	
	Divider,
	CircularProgress	
} from "@material-ui/core";

import { options } from "./chart";
import api from "../../../../services/api";

const useStyles = makeStyles(() => ({
	root: {
		flex: 1,    
	},
	chartContainer: {
		display: "flex",
		justifyContent: "center",
		height: 400,
		position: "relative"
	},
	actions: {
		justifyContent: "flex-end"
	}
}));

const LatestEvaluations = props => {
	const { className, ...rest } = props;
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);

	const classes = useStyles();
  
	useEffect(() => {
		const loadEvaluations = async () => {
			const response = await api.get("dashboard/evaluations");    			

			setData({
				labels: response.data.map((evaluation) => evaluation.label),
				datasets: [
					{
						label: "Total de avaliações",
						backgroundColor: palette.primary.main,
						data: response.data.map((evaluation) => evaluation.count)
					}
				]
			});
			setLoading(false);
		};

		loadEvaluations();
	}, []);

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardHeader				
				title="Avaliações finalizadas nos últimos 7 dias"
			/>
			<Divider />
			<CardContent>
				<div className={classes.chartContainer}>
					{loading? <CircularProgress/>:<Bar
						data={data}
						options={options}
					/>}					
				</div>
			</CardContent>
			<Divider />			
		</Card>
	);
};

LatestEvaluations.propTypes = {
	className: PropTypes.string
};

export default LatestEvaluations;
