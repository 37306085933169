import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
	Box,
	Dialog,	
	IconButton,
	Typography
} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
	root: {    
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {		
		marginLeft: "auto",		
		color: theme.palette.grey[500],
	},
	dialog: {
		minWidth: 400
	},
	link: {
		color: theme.palette.primary.main
	}
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose } = props;
	return (		
		<Box display="flex" textAlign="center" flexDirection="row" p={1}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</Box>		
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(1),
	},
}))(MuiDialogContent);

export default function DialogRender(props) {  
	const { opened, setOpen, dialogTitle, title, text } = props;	
	
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>						
			<Dialog className={styles.dialog} onClose={handleClose} aria-labelledby="customized-dialog-title" open={opened}>
				<DialogTitle id="customized-dialog-title" onClose={handleClose}>{dialogTitle}</DialogTitle>        
				<DialogContent dividers>          
					<Typography variant="h6">
						{title}                          
					</Typography>					
					<Typography gutterBottom>            
						{text}
					</Typography>					
				</DialogContent>				
			</Dialog>				
		</>
	);
}

DialogRender.propTypes = {
	opened: PropTypes.bool,
	setOpen: PropTypes.func,
	dialogTitle: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string	
};
