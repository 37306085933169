/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { List, ListItem, Button, colors, Tooltip } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { signOut } from "../../../../../../store/modules/auth/actions";
import InputIcon from "@material-ui/icons/Input";


const useStyles = makeStyles(theme => ({
	root: {},
	item: {
		display: "flex",
		paddingTop: 0,
		paddingBottom: 0
	},
	button: {
		color: colors.blueGrey[800],
		padding: "10px 8px",
		justifyContent: "flex-start",
		textTransform: "none",
		letterSpacing: 0,
		width: "100%",
		fontWeight: theme.typography.fontWeightMedium
	},
	icon: {
		color: theme.palette.icon,
		width: 24,
		height: 24,
		display: "flex",
		alignItems: "center",
		marginRight: theme.spacing(1)
	},
	active: {
		color: theme.palette.secondary.main,
		fontWeight: theme.typography.fontWeightMedium,
		"& $icon": {
			color: theme.palette.secondary.main
		}
	}
}));

const CustomRouterLink = forwardRef((props, ref) => (
	<div
		ref={ref}
		style={{ flexGrow: 1 }}
	>
		<RouterLink {...props} />
	</div>
));

const SidebarNav = props => {
	const { pages, className, ...rest } = props;
	const user = useSelector((state) => state.user.profile);
	const dispatch = useDispatch();

	const classes = useStyles();
  
	function handleSignOut() {
		dispatch(signOut());
	}

	return (
		<List
			{...rest}
			className={clsx(classes.root, className)}
		>
			{pages.filter(page => page.type && page.type === user.type || page.type===0)
				.map(page => (
					<ListItem
						className={classes.item}
						disableGutters
						key={page.title}
					>
						<Tooltip title={page.description}>
							<Button
								activeClassName={classes.active}
								className={classes.button}
								component={CustomRouterLink}
								to={page.href}
							>
								<div className={classes.icon}>{page.icon}</div>
								{page.title}
							</Button>
						</Tooltip>
					</ListItem>
				))}
			<ListItem
				className={classes.item}
				disableGutters				
			>
				<Tooltip title={"Sair"}>
					<Button
						activeClassName={classes.active}
						className={classes.button}
						component={CustomRouterLink}
						onClick={handleSignOut}						
					>
						<div className={classes.icon}>
							<InputIcon />
						</div>
						Sair
					</Button>
				</Tooltip>
			</ListItem>

		</List>
	);
};

SidebarNav.propTypes = {
	className: PropTypes.string,
	pages: PropTypes.array.isRequired
};

export default SidebarNav;
