import React, {useEffect, useState} from "react";
import * as _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { useSelector } from "react-redux"; 
import MuiDialogContent from "@material-ui/core/DialogContent";
import PerfectScrollbar from "react-perfect-scrollbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { EvaluatorsListModal } from "../../components";
import { toast } from "react-toastify";
import {
	AppBar,
	Toolbar,
	Button,
	Box,
	Card,
	CardActions,
	CardContent,	
	Link,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,	
	TablePagination,
	DialogTitle,
	DialogContentText,
	DialogActions
} from "@material-ui/core";
import api from "../../../../services/api";

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: "relative",
		backgroundColor: theme.palette.primary.main,
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
		color: "#fff",
		fontSize: 16
	},
	row: {
		margin: 0,
		padding: theme.spacing(2),
		display: "flex",
		flex: 1,    
	},
	projectHead: {
		flex: 1,
		display: "flex",      
	},
	projectRow: {
		alignItems: "center",		
	},
	evaluatorsList: {
		display: "flex",
		alignItems: "center"
	},
	addButton: {
		marginLeft: 10,
		backgroundColor: theme.palette.primary.main
	},
	secondaryButton: {
		marginLeft: 10,
		backgroundColor: theme.palette.secondary.main
	},
	link: {
		marginLeft: 10
	}
}));

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

export default function ProjectEvaluationDetails(props) {  
	const { opened, setOpen, updateList } = props;	
	const [projectInfo, setProjectInfo] = useState();
	const classes = useStyles();
	const [page, setPage] = useState(0);  
	const [dialogOpen, setDialogOpen] = useState(false);	
	const projectState = useSelector((state) => state.project.selected) || {};

  	const [evaluationId, setEvaluationId] = useState(0);
	const [removingConflict, setRemovingConflict] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [actionConfirmed, setActionConfirmed] = useState(false);
	const [modal, setModal] = useState({
		title:"",
		message: ""
	});		
	
	const links = [
		{ field: "responsibility_link", label: "Formulário de responsabilidade"},
		{ field: "research_project_link", label: "Projeto de pesquisa"},
		{ field: "expanded_abstract_link", label: "Resumo expandido"},
		{ field: "continuity_link", label: "Projeto de continuidade"},    
		{ field: "poster_link", label: "Banner"},
	];
  
	const youtubeLinks = [
		{ href: (project) => `https://www.youtube.com/watch?v=${project.youtube_link}`, label: "Vídeo de inscrição"},
		{ href: (project) => `https://www.youtube.com/watch?v=${project.evaluation_video_link}`, label: "Vídeo final"},		
	];
  
	const handlePageChange = (event, page) => {		
		setPage(page);
	};	
	
	const handleClose = () => {
		updateList();
		setOpen(false);
	};
  
	function handleClick() {		
		setDialogOpen(true);
	}

	const showRemoveConflictModal = (id) => {
		setModal({title:"Remover conflito", message:"Confirma que deseja remover o conflito de interesse?"});
		setModalOpen(true);
		setRemovingConflict(true);
		setEvaluationId(id);
	}

	const showRemoveEvaluator = (id) => {
		setModal({title:"Remover avaliador", message:"Confirma que deseja remover o avaliador?"});
		setModalOpen(true);
		setRemovingConflict(false);
		setEvaluationId(id);
	}

	const handleModalConfirm = () => {
		setActionConfirmed(true);
		setModalOpen(false);
	}

	const handleModalClose = () => {
		setActionConfirmed(false);
		setModalOpen(false);
	}

	async function loadProjectInfo(){		
		const response = await api.get(`/projects/informations/${projectState.id}`);
    
		setProjectInfo(response.data);
		setActionConfirmed(false);
	}

	async function removeInterestConflict() {
		try {
			await api.put(`evaluations/${evaluationId}/conflicts`, {
				has_interest_conflict: 0
			});

			await loadProjectInfo();
			toast.success(`O conflito de interesse foi removido.`);    			
		}
		catch (err) {
			toast.error("Ops! Tivemos um erro ao remover o conflito de interesse");
		}
	}

	async function removeEvaluator() {
		try {
			await api.delete(`evaluations/${evaluationId}`);

			await loadProjectInfo();
			toast.success(`O avaliador foi removido do projeto.`);    			
		}
		catch (err) {
			toast.error("Ops! Tivemos um erro ao remover o avaliador");
		}
	}
  
	useEffect(() => {	
		if (opened === true)
			loadProjectInfo();
	},[opened]);	

	useEffect(() => {
		if (actionConfirmed && evaluationId > 0) {
			if (removingConflict) {
				removeInterestConflict();
			} else {
				removeEvaluator();
			}
		}
	}, [actionConfirmed])

	return (
		<div>			
			<Dialog 				
				fullScreen 
				onClose={handleClose} 								
				open={opened}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
							<CloseIcon />
						</IconButton>
						<Typography className={classes.title}>							
							{projectState.title} | {projectState.area}-{projectState.subarea}
						</Typography>
						<Button autoFocus color="inherit" onClick={handleClose}>
              Sair
						</Button>
					</Toolbar>
				</AppBar>		
				<EvaluatorsListModal opened={dialogOpen} setOpen={setDialogOpen} updateList={loadProjectInfo}/>
				<Dialog
					open={modalOpen}
					onClose={handleModalClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{modal.title}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{modal.message}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleModalConfirm} color="primary" autoFocus>
							SIM
						</Button>
						<Button onClick={handleModalClose} color="secondary">
							NÃO
						</Button>
					</DialogActions>
				</Dialog>	
				<DialogContent dividers>  					
					<div className={classes.projectHead}>            						
						<div className={classes.projectRow}>
							<Typography variant="h6">Nível</Typography>
					  <Typography>            
					  {projectState.phase === 1? "Projeto em andamento": "Projeto em concepção"}
					  </Typography>
						</div>
						<div className={classes.projectRow} style={{marginLeft: 20}}>
							<Typography variant="h6">Arquivos</Typography>
							<div className={classes.projectHead}>
								{links.map((link) => (
									projectState[link.field] && (
										<Box pr={1}>                    
											<Link 												
												key={link.field}															
												href={projectState[link.field]}    
												target="_blank"
												color="secondary"
												variant="h6">  
												{link.label}
											</Link>							       
										</Box>
									)
								) )}								
								{youtubeLinks.map((link) => (                  
									<Link 																				
										key={link.label}
										className={classes.link}												
										href={link.href(projectState)}  
										target="_blank"
										color="secondary"
										variant="h6">  
										{link.label}
									</Link>            
								))}								
							</div>
						</div>						
					</div>					
					<Typography variant="h6">Resumo</Typography>
					<Typography gutterBottom>            
						{(projectState || {}).abstract}
					</Typography>						
					<div className={classes.row}>						
						<Typography variant="h6" className={classes.evaluatorsList}>Lista de avaliadores</Typography>	          
						<Button 
							color="primary"
							variant="contained" 
							className={classes.addButton}
							onClick={() => handleClick(projectInfo)}>							
              Adicionar							
						</Button>
					</div>			
      	<div className={classes.content}>
						<Card
							className={classes.root}
						>
							<CardContent className={classes.content}>				
								<PerfectScrollbar>
									<div className={classes.inner}>
										<Table>
											<TableHead>
												<TableRow>									
													<TableCell>Avaliador</TableCell>									
													<TableCell>Áreas</TableCell>			
													<TableCell>Tem conflito de interesse?</TableCell>						
													<TableCell>Já avaliou?</TableCell>		
													<TableCell>Nota</TableCell>							
													<TableCell></TableCell>							
												</TableRow>
											</TableHead>
											<TableBody>
												{projectInfo && projectInfo.Evaluations.map(evaluation => (
													<TableRow
														className={classes.tableRow}
														hover
														key={evaluation.id}>																		
														<TableCell>															
															{evaluation.consultant.user.name}
														</TableCell>					
														<TableCell>
															{evaluation.consultant.ExpertiseAreas.map((consultantArea) => (
																<p key={consultantArea.id}>{consultantArea.area.description}</p>
															))}															
														</TableCell>
														<TableCell>
															{evaluation.has_interest_conflict? "Sim": "Não"}
															{evaluation.has_interest_conflict? 
																<Button 
																	color="primary"
																	variant="contained" 
																	className={classes.secondaryButton}
																	onClick={() => showRemoveConflictModal(evaluation.id)}>
												  					Remover							
																</Button> : null}
														</TableCell>
														<TableCell>
															<div>		
																{evaluation.finished? "Sim": "Não"}																
															</div>								
														</TableCell>
														<TableCell>
															<div>		
																{evaluation.finished? evaluation.note: "-"}																
															</div>								
														</TableCell>
														<TableCell>
															<Button 
																color="primary"
																variant="contained" 
																className={classes.secondaryButton}
																onClick={() => showRemoveEvaluator(evaluation.id)}>
																Remover Avaliador						
															</Button>
														</TableCell>													
													</TableRow>
												))}
											</TableBody>
										</Table>
									</div>
								</PerfectScrollbar>
							</CardContent>
							<CardActions className={classes.actions}>
								<TablePagination
									component="div"
									count={((projectInfo && projectInfo.Evaluations) || []).length}
									onChangePage={handlePageChange}									
									labelDisplayedRows={({from, to, count}) => `${from}-${to} de ${count}`}
									page={page}								
									rowsPerPage={10}
									rowsPerPageOptions={[10]}
								/>
							</CardActions>
						</Card>
					</div>		
				</DialogContent>				 
			</Dialog>
		</div>
	);
}
