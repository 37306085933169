import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

import {	
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	Button,
	TextField,
	Typography,
} from "@material-ui/core";
import api from "../../services/api";

const useStyles = makeStyles(() => ({
	root: {},
	errorText: {
		marginTop: "1rem",
		border: "1px solid #f44336",
		padding: 5,
		color: "#f44336"
	},
	passwordInput: {
		marginTop: "1rem"
	},
	grid:{
		height:"500px"
	}
}));

const Password = props => {
	const { history, className, ...rest } = props;
  
	const token = props.location.search;
  
	const classes = useStyles();  

	const [open, setOpen] = React.useState(false);
	const [values, setValues] = useState({
		password: "",
		confirm: "",
	});
  
	const [modal, setModal] = useState({
		title:"",
		message: ""
	});
  
	const updateButtonDisabled = values.password.length < 6 || values.confirm.length < 6   
   || values.password != values.confirm;

	const handleClose = () => {
		setOpen(false);
		if(modal.title === "Sucesso!")
			history.push("/");
	};
  
	const handleChange = event => {
		setValues({
			...values,
			[event.target.name]: event.target.value
		});
	};
  
	const handleClick = async () => {
		try {      
			await api.post(`/users/reset/password/${token}`, {
				password: values.password
			});
			setModal({title:"Sucesso!", message:"A nova senha foi criada com sucesso!"});
			setOpen(true);
		} catch (err) {
			if(err.response.data.error === "Invalid token")
				setModal({ title: "Atenção!", message: "O token expirou, solicite novamente a recuperação de senha" });
			else
				setModal({title:"Atenção!", message:"Nosso servidor está temporáriamente indisponível, tente novamente mais tarde!"});
			setOpen(true);
		}
	};

	return (
		<div className={classes.root}>
			<Grid className={classes.grid} container spacing={3} direction="row" justify="center" alignItems="center">
				<Grid item sm={6} xs={10}>
					<Card
						{...rest}
						className={clsx(classes.root, className)}
					>
						<form>
							<CardHeader
								subheader="Nova a senha"
								title="Senha"
							/>
							<Divider />
							<CardContent>
								<TextField
									className={classes.passwordInput}
									fullWidth
									label="Nova senha* (mínimo de 6 caracteres)"
									name="password"
									onChange={handleChange}
									type="password"
									value={values.password}
									variant="outlined"
								/>
								<TextField
									className={classes.passwordInput}
									fullWidth
									label="Confirmar nova senha* (mínimo de 6 caracteres)"
									name="confirm"
									onChange={handleChange}						
									type="password"
									value={values.confirm}
									variant="outlined"
								/>
								{updateButtonDisabled && (
            <>
            {values.password.length < 6 && values.confirm.length < 6 && (
            	<Typography className={classes.errorText} variant="h6">
							 A nova senha deve ter no mínimo 6 caracteres							              					
					    </Typography>
            )}						
            {values.password !== values.confirm && (
            	<Typography className={classes.errorText} variant="h6">
            	As senhas estão diferentes
            	</Typography>
            )}            
            </>
								)}          
							</CardContent>
							<Divider />
							<CardActions>					
								<Button
									color="primary"
									variant="outlined"
									disabled={updateButtonDisabled}
									onClick={handleClick}
								>
            Atualizar
								</Button>					
							</CardActions>
						</form>
					</Card>
				</Grid>
			</Grid>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{modal.title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{modal.message}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary" autoFocus>
                      Ok
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

Password.propTypes = {
	className: PropTypes.string
};

export default Password;
